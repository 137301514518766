import { Th, Td } from '@chakra-ui/react';
import styled from 'styled-components';

export const ReadMoreWrapper = styled.div`
  font-weight: 900;
  color: ${(props) => props.theme.color.primary700};
  cursor: pointer;
  margin: ${(props) => props.theme.space.md} 0;
`;

export const ReadMoreLessText = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  img {
    margin-left: ${(props) => props.theme.space.md};
  }
`;

export const TableWrapper = styled.div`
  & > div > table > tbody > tr {
    &:nth-child(odd) {
      background-color: #f3eafb;
    }
    &:nth-child(even) {
      background-color: ${(props) => props.theme.color.white};
    }
  }
`;
export const TableItemsTitle = styled(Th)`
  font-size: ${(props) => props.theme.font.md} !important;
  font-weight: 800 !important;
  line-height: 18px !important;
  color: ${(props) => props.theme.color.gray900} !important;
`;

export const TableCell = styled(Td)`
  font-size: ${(props) => props.theme.font.sm} !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: ${(props) => props.theme.color.gray900} !important;
`;
