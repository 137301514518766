import { FieldError } from 'react-hook-form';

import CountriesDropDown from '../CountriesDropDown/CountriesDropDown';
import FieldWarning from '../FieldWarning/FieldWarning';
import {
  InputElement,
  InputLeftAddonElement,
  InputPhoneWrapper,
} from './InputPhone.styles';

export type Options = {
  label: string;
  value: string;
};
export type InputPhoneProps = {
  id?: string;
  size?: 'sm' | 'md' | 'lg';
  placeholder?: string;
  register?: object;
  isInvalid?: FieldError | boolean;
  onSelectCountryCode: (countryCode: string, flag: string) => void;
  defaultCountryCode: string;
  defaultCountryFlag: string;
};
const InputPhone = ({
  id,
  placeholder,
  size = 'lg',
  register = {},
  isInvalid,
  onSelectCountryCode,
  defaultCountryCode,
  defaultCountryFlag,
}: InputPhoneProps): JSX.Element => {
  return (
    <InputPhoneWrapper
      variant="phone"
      $isInvalid={Boolean(isInvalid)}
      size={size}
    >
      <InputLeftAddonElement
        children={
          <CountriesDropDown
            defaultCountryFlag={defaultCountryFlag}
            defaultCountryCode={defaultCountryCode}
            isInvalid={Boolean(isInvalid)}
            onSelectCountryCode={onSelectCountryCode}
          />
        }
      />
      <InputElement
        id={id}
        type="text"
        {...register}
        placeholder={placeholder}
      />
      {isInvalid && <FieldWarning />}
    </InputPhoneWrapper>
  );
};
export default InputPhone;
