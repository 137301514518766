import React from 'react';
import Icon from '../Icon/Icon';
import { IconTypes } from '../Icon/types';
import {
  ActionButton,
  ModalHeaderWrapper,
  ModalTitle,
  CloseButton,
  ModalHeaderContent,
} from './ModalHeader.styles';

type UploadImageModalHeaderProps = {
  headerTitle: string;
  isTitleCentered?: boolean;
  hasSeparationLine?: boolean;
  backgroundColor?: string;
  textColor?: string;
  onCloseClicked: () => void;
  onGoBackClicked?: () => void;
  closeButtonVariant?: 'primary' | 'secondary';
  icon?: React.ReactElement | undefined;
  subContent?: false | React.ReactElement;
  isTitleTextCentered?: boolean;
  headerSize?: 'sm' | 'md' | 'lg' | 'xl';
};

const ModalHeader = ({
  onCloseClicked,
  onGoBackClicked,
  headerTitle,
  isTitleCentered,
  backgroundColor,
  textColor,
  headerSize = 'xl',
  closeButtonVariant = 'primary',
  hasSeparationLine = true,
  subContent,
  icon,
  isTitleTextCentered = false,
}: UploadImageModalHeaderProps) => {
  return (
    <ModalHeaderWrapper
      $hasSeparationLine={hasSeparationLine}
      $backgroundColor={backgroundColor}
    >
      {(isTitleCentered || Boolean(onGoBackClicked)) && (
        <ActionButton onClick={onGoBackClicked}>
          {Boolean(onGoBackClicked) && (
            <Icon type="leftArrow" size="sm" color="gray600" />
          )}
        </ActionButton>
      )}

      {icon}

      <ModalHeaderContent $isTitleTextCentered={isTitleTextCentered}>
        <ModalTitle $textColor={textColor} $headerSize={headerSize}>
          {headerTitle}
        </ModalTitle>
        {subContent}
      </ModalHeaderContent>

      <CloseButton onClick={onCloseClicked} $variant={closeButtonVariant}>
        <Icon type="cross" size="smd" color={textColor || ('gray600' as any)} />
      </CloseButton>
    </ModalHeaderWrapper>
  );
};

export default ModalHeader;
