import {
  Table as ChakraTable,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useState } from 'react';

import Icon from '../Icon/Icon';
import {
  ReadMoreLessText,
  ReadMoreWrapper,
  TableItemsTitle,
  TableCell,
  TableWrapper,
} from './Table.styles';

export type TableTypeProps = {
  data?: {
    header: string[];
    content: Array<string[]>;
  };
  /**
   * primary: White header and gray columns
   * reverted: Gray header and white columns
   * zebra: White header, gray and white columns
   * zebraReverted: lightgray header, gray and lightgray columns
   */
  type?:
    | 'primary'
    | 'reverted'
    | 'zebra'
    | 'zebraReverted'
    | 'schoolInfoReverted'
    | 'schoolInfoZebra';
  caption?: string;
};

type ExpandedItem = {
  rowIndex: number;
  columnIndex: number;
};

const contentTextLimit = 150;

const Table = ({
  data,
  type = 'primary',
  caption,
}: TableTypeProps): JSX.Element => {
  const [expandedItems, setExpandedItems] = useState<ExpandedItem[]>();

  const toggleText = (
    rowIndex: number,
    columnIndex: number,
    isItemExpanded: boolean
  ) => {
    if (isItemExpanded) {
      setExpandedItems(
        expandedItems?.filter(
          (item) =>
            item.rowIndex !== rowIndex && item.columnIndex !== columnIndex
        )
      );
    } else {
      setExpandedItems([...(expandedItems || []), { rowIndex, columnIndex }]);
    }
  };
  return (
    <TableWrapper>
      <TableContainer>
        <ChakraTable
          size="sm"
          overflow="auto"
          whiteSpace="pre-line"
          variant={type}
        >
          {caption && (
            <TableCaption>Imperial to metric conversion factors</TableCaption>
          )}
          <Thead>
            <Tr>
              {data?.header.map((headerItem) => (
                <TableItemsTitle key={headerItem}>{headerItem}</TableItemsTitle>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data?.content.map((rowItem, rowIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Tr key={`${rowItem.toString()}_${rowIndex}`}>
                {rowItem.map((columnItem, columnIndex) => {
                  const fullText = columnItem;
                  const displayReadMoreButton =
                    fullText.length > contentTextLimit;
                  const shortText = fullText?.substring(0, contentTextLimit);
                  const isItemExpanded =
                    expandedItems?.some(
                      (item) =>
                        item.rowIndex === rowIndex &&
                        item.columnIndex === columnIndex
                    ) || false;

                  return (
                    <TableCell key={columnItem}>
                      {isItemExpanded ? fullText : shortText}
                      {displayReadMoreButton && !isItemExpanded && '...'}
                      {displayReadMoreButton && (
                        <ReadMoreWrapper
                          onClick={() =>
                            toggleText(rowIndex, columnIndex, isItemExpanded)
                          }
                        >
                          {isItemExpanded ? (
                            <ReadMoreLessText>
                              <p>Read less</p>
                              <Icon
                                type="upArrow"
                                size="xs"
                                color="primary700"
                              />
                            </ReadMoreLessText>
                          ) : (
                            <ReadMoreLessText>
                              <p>Read more</p>
                              <Icon
                                type="downArrow"
                                size="xs"
                                color="primary700"
                              />
                            </ReadMoreLessText>
                          )}
                        </ReadMoreWrapper>
                      )}
                    </TableCell>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </ChakraTable>
      </TableContainer>
    </TableWrapper>
  );
};

export default Table;
