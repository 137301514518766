import { Input } from '@chakra-ui/react';
import Link from 'next/link';
import styled from 'styled-components';

import media from '@/modules/media';

export const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.color.gray900};
`;

export const FooterWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  color: white;
  padding: 48px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.gray900};

  ${media.desktop} {
    padding: ${(props) => `${props.theme.space.xxl} ${props.theme.space.lg}`};
  }

  ${media.tablet} {
    padding: ${(props) => `${props.theme.space.xxl} ${props.theme.space.xl}`};
  }

  ${media.phone} {
    padding: 48px 12px;
  }
`;

export const Newsletter = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.space.xxl};

  ${media.tabletLandscape} {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  ${media.phone} {
    margin-bottom: 0;
  }
`;

export const IconWrapper = styled.div`
  width: fit-content;

  ${media.tabletLandscape} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const NewsLetterActions = styled.div`
  max-width: 832px;
  width: 100%;
`;

export const NewsletterHeader = styled.h5`
  color: white;
  font-size: ${(props) => props.theme.font.xl};
  font-weight: 700;

  ${media.desktop} {
    width: 100%;
  }

  ${media.phone} {
    font-size: ${(props) => props.theme.font.lg};
    margin-bottom: ${(props) => props.theme.space.xl};
  }
`;

export const NewsletterForm = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${media.desktop} {
    margin-bottom: ${(props) => props.theme.space.xxl};
    flex-direction: column;
    align-items: flex-start;
  }

  ${media.custom(940)} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${(props) => props.theme.space.xl};
  }

  ${media.phone} {
    margin: 0;
    display: block;
  }
`;

export const ResponsivenessWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

export const NewsletterInput = styled(Input).attrs({
  width: '336px',
  height: '40px',
})`
  border-radius: 8px;
  padding: ${(props) => `${props.theme.space.mdl} ${props.theme.space.lg}`};
  color: ${(props) => props.theme.color.gray500};
  font-size: ${(props) => props.theme.font.md};

  ${media.desktop} {
    margin: 0;
  }

  ${media.custom(940)} {
    margin: 0;
  }

  ${media.phone} {
    min-width: unset;
    margin-bottom: ${(props) => props.theme.space.xl};
    width: 100% !important;
  }
`;

export const NewsLetterThankyouWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: ${(props) => props.theme.font.xl};
  line-height: ${(props) => props.theme.space.xxl};
`;

export const FooterDivider = styled.hr`
  border-top: 1px solid ${(props) => props.theme.color.gray700};
`;

export const MobileFooterDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.color.gray700};
  margin: ${(props) => props.theme.space.xl} 0;
`;

export const StudyHallInfo = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.space.xxl};
  justify-content: space-between;

  ${media.desktop} {
    flex-direction: column;
  }
`;

export const FAQs = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  max-width: 900px;

  ${media.bigDesktop} {
    gap: ${(props) => props.theme.space.xl};
  }

  ${media.tabletLandscape} {
    max-width: 781px;
  }

  ${media.desktop} {
    flex-direction: column;
    gap: ${(props) => props.theme.space.xxl};
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.xl};
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;

  ${media.desktop} {
    gap: ${(props) => props.theme.space.lg};
  }
`;

export const InfoHeader = styled.h4`
  font-size: ${(props) => props.theme.font.lg};
  font-weight: 900;
  color: white;
`;

export const ContactsInfo = styled.a`
  display: flex;
  gap: ${(props) => props.theme.space.mdl};
  align-items: center;
`;
export const Socials = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space.md};
  max-width: 150px;
  flex-wrap: wrap;
`;

export const SocialLink = styled(Link)`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.theme.color.gray700};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  cursor: pointer;
`;

export const CopyRights = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${(props) => props.theme.font.md};
  margin-top: ${(props) => props.theme.space.xxxl};
  margin-bottom: ${(props) => props.theme.space.md};
  margin: ${(props) => `${props.theme.space.xxxl} 0 ${props.theme.space.md} 0`};
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;

  ${media.phone} {
    flex-direction: column-reverse;
    gap: ${(props) => props.theme.space.xl};
  }
`;

export const CopyrightSmall = styled.div`
  gap: ${(props) => props.theme.space.xl};
  display: flex;
  text-align: center;

  ${media.phone} {
    width: 100%;
    align-items: center;
  }

  button {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
  }
`;
