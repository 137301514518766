import { useState } from 'react';
import ImageUploading, {
  ErrorsType,
  ImageListType,
} from 'react-images-uploading';

import { MainPhotoDisplay } from '@/common/ActionAvatar/ActionAvatar.styles';
import Icon from '@/common/Icon/Icon';
import ModalHeader from '@/common/ModalHeader/ModalHeader';
import Btn from '@/components/common/Btn/Btn';
import { ModalBodyContainer } from '@/styles/pagesStyles/learner/registration/addPhoto.styles';

import {
  ButtonCancel,
  ErrorMessage,
  ImageInsertWrapper,
  Note,
  NoteText,
  Or,
  PhotoWrapper,
  Title,
  TitleTabletWrapper,
} from './ImageInsert.styles';

type ImageInsertProps = {
  imageFile: any;
  onChangeImage: (imageFile: any) => void;
  onHandleModal: () => void;
  profilePictureUrl: string;
};

const ImageInsert = ({
  imageFile,
  onChangeImage,
  onHandleModal,
  profilePictureUrl,
}: ImageInsertProps) => {
  const [imageUploadError, setImageUploadError] = useState<string | null>(null);
  const screenWidth = window.outerWidth;

  return (
    <>
      <ModalHeader
        headerTitle="Add profile photo"
        onCloseClicked={onHandleModal}
        isTitleCentered
      />
      <ModalBodyContainer>
        <ImageUploading
          value={imageFile as ImageListType}
          onChange={onChangeImage}
          acceptType={['jpg', 'jpeg', 'png', 'webp']}
          dataURLKey="data_url"
          maxFileSize={5000000} // 5 Mb in Bytes
          onError={(errors: ErrorsType) => {
            if (errors) {
              if (errors.acceptType)
                setImageUploadError(
                  'Supported image file types: .jpg .jpeg .png .webp'
                );
              else if (errors.maxFileSize)
                setImageUploadError('Maximum image file size is 5 Mb');
            }
          }}
        >
          {({ onImageUpload, dragProps }) => (
            <ImageInsertWrapper {...dragProps}>
              <TitleTabletWrapper>
                <ButtonCancel onClick={onHandleModal}>Cancel</ButtonCancel>
                <Title>Add profile photo</Title>
              </TitleTabletWrapper>
              <PhotoWrapper $isEmoji={profilePictureUrl.includes('default')}>
                <MainPhotoDisplay
                  src={profilePictureUrl}
                  size="lg"
                  alt="profile img"
                  $isGrayedOut={
                    Boolean(imageFile) === false &&
                    profilePictureUrl.includes('default')
                  }
                />
              </PhotoWrapper>

              {imageUploadError && (
                <ErrorMessage>{imageUploadError}</ErrorMessage>
              )}
              <Note>
                <Icon type="infoOutlined" color="primary700" />
                <NoteText>We do not recommend using a dark photo.</NoteText>
              </Note>
              <Title>Drag photo here</Title>

              <Or>or</Or>

              <Btn
                size="lg"
                variant="secondaryOutlined"
                leftIcon={<Icon type="download" size="sm" />}
                onClick={onImageUpload}
              >
                {screenWidth > 490
                  ? 'Upload from computer'
                  : 'Upload from device'}
              </Btn>
            </ImageInsertWrapper>
          )}
        </ImageUploading>
      </ModalBodyContainer>
    </>
  );
};

export default ImageInsert;
