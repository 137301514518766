import { Tab, TabList, TabPanels } from '@chakra-ui/react';
import styled from 'styled-components';
import media from '@/modules/media';
import Icon from '../Icon/Icon';

export const CustomTab = styled(Tab)`
  min-width: auto !important;
`;

export const IconElement = styled(Icon)`
  margin-right: 4px;
`;

export const CustomTabList = styled(TabList)<{
  $hasPaddingSet: boolean;
  $isOutContainer?: boolean;
}>`
  padding: ${(props) => props.$hasPaddingSet && '0px 24px 0px 32px !important'};
  ${(props) =>
    props.$isOutContainer &&
    `
      position: relative;
      left: -32px;
      width: calc(100% + 64px) !important;

      ${media.tabletLandscape} {
        left: -32px !important;
      }
  `}
`;

export const CustomTabsPanel = styled(TabPanels)<{ $isCentered: boolean }>`
  display: ${(props) => props.$isCentered && 'flex'};
  justify-content: ${(props) => props.$isCentered && 'center'};
`;
