import { AiStudyGuidTypes } from '@/types/common';
import { Fragment, useEffect, useState } from 'react';
import { HighlightedText, TypingTextAnimation } from './TypingText.styles';

const FULLSTOP_DELAY = 2000;
const COMMA_DELAY = 1000;

const splitSentence = (
  sentence: string
): { firstHalf: string; delay: number } => {
  let delay = 0;

  // Converts !? into .
  const processedText = sentence.replace(/[!?.]/g, '.');
  // Find the index of comma
  const commaIndex = sentence.indexOf(',');
  // Find the index of fullStop
  const fullStopIndex = processedText.indexOf('.');
  // Find lastCharacter index
  const lastCharacterIndex = sentence.length - 1;
  let firstHalf = '';

  if (
    processedText.includes('.') &&
    (!(fullStopIndex + 10 >= lastCharacterIndex) ||
      (fullStopIndex < commaIndex && commaIndex))
  ) {
    firstHalf = sentence.slice(0, fullStopIndex + 1);
    delay = FULLSTOP_DELAY;
  } else if (sentence.includes(',')) {
    firstHalf = sentence.slice(0, commaIndex + 1);
    delay = COMMA_DELAY;
  }
  return {
    firstHalf,
    delay,
  };
};

const renderText = (text: string, textToHighlight: string): JSX.Element => {
  const regex = new RegExp(textToHighlight, 'gi');
  const parts = text.split(regex);
  return (
    <>
      {parts.map((part, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={i}>
          {part}
          {i < parts.length - 1 && (
            <HighlightedText $isBlueColor={textToHighlight === 'Safe Mode.'}>
              {textToHighlight}
            </HighlightedText>
          )}
        </Fragment>
      ))}
    </>
  );
};

const TypingText = ({
  text,
  textToHighlight,
  activeQuestion,
  initialDelay = 1000,
  hasDelay = true,
  type = 'DASHBOARD',
}: {
  text: string;
  textToHighlight?: string;
  activeQuestion?: number;
  initialDelay?: number;
  hasDelay?: boolean;
  type?: AiStudyGuidTypes;
}) => {
  const [currentText, setCurrentText] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [hasInitialDelay, setHasInitialDelay] = useState<boolean>(false);

  const { firstHalf, delay } = splitSentence(text);

  const pauseDelay: number = (hasDelay && delay) as number;

  useEffect(() => {
    setCurrentIndex(0);
    setCurrentText('');
    setIsExpanded(false);
    setHasInitialDelay(false);
  }, [activeQuestion, type]);

  useEffect(() => {
    let timer: any;

    if (currentIndex <= text.length) {
      if (firstHalf.length >= currentIndex) {
        // Sets an initial delay to the firstHalf of the text to show
        timer = setTimeout(() => {
          setHasInitialDelay(true);
        }, initialDelay);
        if (hasInitialDelay) {
          timer = setTimeout(() => {
            setCurrentText(firstHalf.substring(0, currentIndex));
            setCurrentIndex(currentIndex + 1);
          }, 25);
        }
      } else {
        // Sets an additional delay for second half of the sentence
        timer = setTimeout(() => {
          setIsExpanded(true);
        }, pauseDelay);
      }

      if (isExpanded) {
        timer = setTimeout(() => {
          setCurrentText(text.substring(0, currentIndex));
          setCurrentIndex(currentIndex + 1);
        }, 25);
      }
    }

    if (currentIndex - 1 > text.length) {
      setCurrentIndex(0);
    }

    return () => {
      clearTimeout(timer);

      if (currentIndex > text.length) {
        setCurrentIndex(0);
        setIsExpanded(false);
        setHasInitialDelay(false);
      }
    };
  }, [text, currentIndex, isExpanded, hasInitialDelay]);

  return (
    <TypingTextAnimation
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      $isCursorBlinking={currentIndex <= text.length}
    >
      {renderText(currentText, textToHighlight as string)}
    </TypingTextAnimation>
  );
};

export default TypingText;
