import media from '@/modules/media';
import { Button } from '@chakra-ui/react';
import ReactCrop from 'react-image-crop';
import styled from 'styled-components';

export const TitleTabletWrapper = styled.div`
  padding: 43px 0 12px;
  display: none;
  min-height: 72px;

  ${media.tabletLandscape} {
    display: block;
  }
`;

export const Title = styled.p`
  font-size: ${(props) => props.theme.font.lg};
  font-weight: 900;
  text-align: center;
  color: ${(props) => props.theme.color.gray900};
`;

export const ButtonCancel = styled(Button)`
  position: absolute !important;
  left: 16px;
  top: 36px;
  background-color: transparent !important;
  color: ${(props) => props.theme.color.gray900} !important;
  padding: 0 !important;
`;

export const ButtonSave = styled(Button)`
  position: absolute !important;
  right: 16px;
  top: 36px;
  background-color: transparent !important;
  color: ${(props) => props.theme.color.primary600} !important;
  padding: 0 !important;
`;

export const PhotoWrapper = styled.div`
  margin-top: ${(props) => props.theme.space.xxl};
  margin-bottom: ${(props) => props.theme.space.xxxl};
  min-height: 334px;

  & img {
    transform: scale(1);
  }
`;

export const PhotoDisplay = styled.img`
  display: block;
`;

export const ErrorMessage = styled.p``;

export const BtnSave = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${media.tabletLandscape} {
    display: none !important;
  }
`;

export const CropArea = styled(ReactCrop).attrs({
  minWidth: 200,
  minHeight: 200,
  aspect: 1 / 1,
  lockAspectRatio: true,
})`
  max-height: 296px;
  margin-bottom: -${(props) => props.theme.space.sm};

  ${media.tabletLandscape} {
    max-height: 467px;
  }

  .ReactCrop__crop-selection {
    border: none;
    border-radius: 40%;
    box-shadow: 0 0 0 9999em rgb(255 255 255 / 70%);
    max-width: 350px;
    max-height: 350px;

    ${media.tabletLandscape} {
      max-width: 470px;
      max-height: 470px;
    }
  }
  .ReactCrop__crop-selection::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid ${(props) => props.theme.color.primary300};
  }

  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
    border-color: ${(props) => props.theme.color.primary600};
    border-style: solid;
    border-width: 3px;
    color: transparent;
    margin: -2px;
  }
  .ReactCrop__drag-handle::after {
    background-color: transparent;
    border: none;
  }

  .ReactCrop__drag-handle.ord-ne {
    border-bottom: none;
    border-left: none;
  }
  .ReactCrop__drag-handle.ord-nw {
    border-bottom: none;
    border-right: none;
  }
  .ReactCrop__drag-handle.ord-sw {
    border-top: none;
    border-right: none;
  }
  .ReactCrop__drag-handle.ord-se {
    border-top: none;
    border-left: none;
  }
`;
