import { InputGroup } from '@chakra-ui/react';
import styled from 'styled-components';

import { PasswordStateType } from '../../../types/common';

type PasswordPropsType = {
  $passwordState: PasswordStateType;
  $isDisabled: boolean;
};

// TODO This can be fixed better. Most likely there in no need for passwordState
// also then function can simple get the props as parameter.
const colorDependingOnPasswordState = (
  $passwordState: PasswordStateType,
  color: { [key: string]: string }
) => {
  let result;

  if ($passwordState === 'notProvided') {
    result = ` ${color.gray25}`;
  } else if (
    $passwordState === 'required' ||
    $passwordState === 'providedAndInvalid'
  ) {
    result = ` ${color.error800} !important;`;
  } else if ($passwordState === 'providedAndValid') {
    result = ` ${color.success700};`;
  }

  return result;
};

export const PasswordGroupWrapper = styled(InputGroup)<PasswordPropsType>`
  ${(props) =>
    `border-color : ${colorDependingOnPasswordState(
      props.$passwordState,
      props.theme.color
    )};`}

  ${(props) =>
    props.$isDisabled && `border : 1px solid ${props.theme.color.gray200};`}

  border-width: 1px;
  border-radius: 8px;
  background-color: ${(props) => props.$isDisabled && props.theme.color.gray25};
  color: ${(props) => props.$isDisabled && props.theme.color.gray500};
  &:focus-within {
    border-color: ${(props) =>
      props.$passwordState === 'notProvided' && props.theme.color.gray200};
    box-shadow: ${(props) =>
      props.$passwordState === 'providedAndInvalid' ||
      props.$passwordState === 'required'
        ? 'none'
        : `0px 0px 0px 1px ${props.theme.color.gray200}`};
    img {
      z-index: 1;
    }
  }
`;
