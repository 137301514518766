import { Modal, ModalOverlay } from '@chakra-ui/react';
import React from 'react';

import { CustomModalContent } from '@/components/common/CustomModal/CustomModal.styles';

type CustomModalProps = {
  isOpen: boolean;
  onClose: () => void;
  cancelRef: any;
  children?: React.ReactNode;
  overlayVisible?: boolean;
};

const CustomModal = ({
  isOpen,
  onClose,
  cancelRef,
  overlayVisible = true,
  children,
}: CustomModalProps): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
      autoFocus={false}
      closeOnOverlayClick={false}
      blockScrollOnMount={false}
      scrollBehavior="outside"
      finalFocusRef={cancelRef}
      variant="learnerModal"
    >
      {overlayVisible && <ModalOverlay />}
      <CustomModalContent>{children}</CustomModalContent>
    </Modal>
  );
};
export default CustomModal;
