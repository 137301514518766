import { InputRightAddon } from '@chakra-ui/react';

import Icon from '../Icon/Icon';

const FieldWarning = (): JSX.Element => (
  <InputRightAddon
    pr="20px"
    maxHeight="100%"
    children={<Icon type="warning" size="sm" color="red" />}
  />
);

export default FieldWarning;
