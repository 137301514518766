import { InputGroup } from '@chakra-ui/react';
import styled from 'styled-components';

import theme from '../../../styles/theme';

type StyledProps = {
  $isInvalid: boolean;
  $hasLeftIcon?: boolean;
  variant: 'primary' | 'secondary' | 'tertiary';
  height?: string;
};

const getBorderColorsConfig = {
  primary: 'white',
  secondary: 'gray25',
  tertiary: 'gray200',
};

const getFocusColorsConfig = {
  primary: 'gray200',
  secondary: 'gray200',
  tertiary: 'gray300',
};

export const Wrapper = styled(InputGroup)<StyledProps>`
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  border-radius: 8px;
  border-width: 1px;
  border-color: ${(props) =>
    props.$isInvalid && props.variant === 'primary'
      ? ` ${theme.colors.brand.error800} ;`
      : ` ${theme.colors.brand[getBorderColorsConfig[props.variant]]}`};

  &:focus-within {
    border-width: 1px;
    border-color: ${(props) =>
      props.$isInvalid
        ? ` ${theme.colors.brand.error700} ;`
        : ` ${theme.colors.brand[getFocusColorsConfig[props.variant]]}`};
    box-shadow: ${(props) =>
      !props.$isInvalid
        ? `0px 0px 0px 1px ${props.theme.color.gray200}`
        : `none`};

    ${(props) =>
      props.$hasLeftIcon
        ? `
    & div:nth-child(1) > img {
      transform: translateX(0px);
    }

    & img {
      transform: translateX(1px);
    }
  `
        : `& img {
  transform: translateX(1px);
}
`}
  }

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    --webkit-appearance: none;
  }
`;
