import { TabPanel, Tabs } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { IconTypes } from '../Icon/types';
import {
  CustomTab,
  CustomTabList,
  CustomTabsPanel,
  IconElement,
} from './TabsMenu.styles';

type TabsData = {
  title: string;
  component: ReactNode;
  code?: string;
  icon?: IconTypes;
};

type TabsMenuProps = {
  data: TabsData[];
  type?: 'primaryTab' | 'subTab';
  hasLayoutUrlParams?: boolean;
  areTabsDisabled?: boolean;
  variant?: string;
  children?: ReactNode;
  alternativeGlobalComponent?: ReactNode;
  isContentCentered?: boolean;
  hasPaddingSet?: boolean;
  onTabChange?: (index: number) => void;
  isOutContainer?: boolean;
  isLazy?: boolean;
};

const findTabIndex = (data: TabsData[], tab = '') => {
  const title = tab.replace('_', ' ');
  const index = data?.findIndex(
    (tabData) =>
      tabData.code?.trim() === title ||
      tabData.title?.trim() === title ||
      tabData.code?.trim() === tab ||
      tabData.title?.trim() === tab
  );
  return index === -1 ? 0 : index;
};

const TabsMenu = ({
  data,
  type = 'primaryTab',
  hasLayoutUrlParams = true,
  areTabsDisabled = false,
  variant,
  children,
  isOutContainer,
  alternativeGlobalComponent,
  isContentCentered = false,
  hasPaddingSet = true,
  onTabChange,
  isLazy = true,
}: TabsMenuProps): JSX.Element => {
  const router = useRouter();
  const {
    expanded,
    exam,
    tab,
    subTab,
    selected,
    subject,
    subjectGroupName,
    offset,
    learnerId,
    examId,
    resourceId,
    isbn,
  } = router.query;

  const isSubcategoryOfStudyHallSelected = router.asPath.includes(
    '/learner/assessment-hall'
  );

  const isStudentSchool = router.asPath.includes('/student/school');

  const isParentMainTabs =
    router.asPath.includes('/parent/learner/') &&
    router.asPath.includes('/exam/');

  const handleOnChange = (index: number) => {
    if (onTabChange) {
      onTabChange(index);
    }

    if (type === 'primaryTab') {
      router.replace(
        {
          pathname: '',
          query: {
            ...(hasLayoutUrlParams && !isStudentSchool
              ? { expanded, selected, exam, offset }
              : {}),
            ...(isParentMainTabs ? { exam, learnerId } : {}),
            ...(isSubcategoryOfStudyHallSelected ? { exam } : {}),
            ...(isStudentSchool && { selected, subject, subjectGroupName }),
            tab: `${data[index]?.title?.replace(/\s/g, ' ').trim()}`,
          },
        },
        undefined,
        { shallow: true }
      );
    } else if (type === 'subTab') {
      router.replace(
        {
          pathname: '',
          query: {
            ...(expanded ? { expanded } : {}),
            ...(selected ? { selected } : {}),
            ...(offset ? { offset } : {}),
            ...(tab ? { tab } : {}),
            ...(exam ? { exam } : {}),
            ...(learnerId ? { learnerId } : {}),
            ...(examId ? { examId } : {}),
            ...(isSubcategoryOfStudyHallSelected ? { exam } : {}),
            ...(resourceId ? { resourceId } : {}),
            ...(isbn ? { isbn } : {}),
            subTab: `${
              data[index].code
                ? data[index].code?.replace(/\s/g, ' ').trim()
                : data[index].title.replace(/\s/g, ' ').trim()
            }`,
          },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  const findDefaultIndex = () => {
    let defaultIndex = 0;
    if (type === 'primaryTab') {
      defaultIndex = findTabIndex(data, tab?.toString());
    } else if (type === 'subTab') {
      defaultIndex = findTabIndex(data, subTab?.toString());
    }
    return defaultIndex;
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {router.isReady && (
        <Tabs
          isLazy={isLazy}
          index={findDefaultIndex()}
          defaultIndex={0}
          variant={variant}
          onChange={(index) => handleOnChange(index)}
        >
          <CustomTabList
            $hasPaddingSet={hasPaddingSet}
            $isOutContainer={isOutContainer}
          >
            {data?.map(({ title, icon }) => {
              return (
                <CustomTab
                  key={`${title}`}
                  isDisabled={areTabsDisabled}
                  data-ftue={
                    title === 'Study Groups'
                      ? 'step-teacher-4'
                      : title === 'Students' && 'step-teacher-2'
                  }
                >
                  {icon && <IconElement type={icon} size="smd" />}
                  {title}
                </CustomTab>
              );
            })}
          </CustomTabList>
          {children}
          <CustomTabsPanel $isCentered={isContentCentered}>
            {alternativeGlobalComponent ||
              data?.map(({ title, component }) => (
                <TabPanel key={`${title}`}>{component}</TabPanel>
              ))}
          </CustomTabsPanel>
        </Tabs>
      )}
    </>
  );
};

export default TabsMenu;
