import { ModalHeader } from '@chakra-ui/react';
import styled from 'styled-components';
import media from '@/modules/media';

type ModalHeaderWrapperType = {
  $hasSeparationLine: boolean;
  $backgroundColor?: string;
};

export const ModalHeaderWrapper = styled(ModalHeader)<ModalHeaderWrapperType>`
  display: flex;
  gap: 8px;
  padding: 24px !important;
  padding-bottom: 16px !important;
  border-bottom: ${(props) =>
    props.$hasSeparationLine
      ? `1.5px solid ${props.theme.color.gray100}`
      : 'none'};

  ${(props) =>
    props.$backgroundColor &&
    `
    background-color: ${props.theme.color[props.$backgroundColor]};
  `}
`;

export const ModalHeaderContent = styled.div<{ $isTitleTextCentered: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: ${(props) =>
    props.$isTitleTextCentered ? 'center' : 'flex-start'};
  gap: 8px;
  justify-content: center;
`;

export const ModalTitle = styled.p<{
  $textColor?: string;
  $headerSize?: 'sm' | 'md' | 'lg' | 'xl';
}>`
  font-size: ${(props) =>
    props.$headerSize
      ? props.theme.font[props.$headerSize]
      : props.theme.font.xl} !important;
  font-weight: 900;
  color: ${(props) =>
    props.$textColor
      ? props.theme.color[props.$textColor]
      : props.theme.color.gray900};
  ${media.phone} {
    font-size: ${(props) =>
      props.$headerSize
        ? props.theme.font[props.$headerSize]
        : props.theme.font.md} !important;
  }
`;

export const ModalAlternativeTitle = styled(ModalTitle)`
  flex: 1;
`;

export const ActionButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding-top: ${(props) => props.theme.space.md};
`;

export const CloseButton = styled.div<{ $variant: 'primary' | 'secondary' }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  cursor: pointer;
  padding: 0;
  width: 32px;
  border-radius: 50%;
  height: 32px;

  ${(props) =>
    props.$variant === 'secondary' &&
    `
    justify-content: center;
    background-color:  ${props.theme.color.gray100};
    & img {
      width: 18px;
      height: 18px;
    }
    `};
`;
