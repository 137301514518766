import styled from 'styled-components';

import {
  FriendInformationType,
  LearnerStatusType,
} from '../../../types/common';

type CardDetailsType = {
  type?: FriendInformationType;
  status?: LearnerStatusType;
  isHidden?: boolean;
};

export const StatusWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${(props) => props.theme.space.sm};
  margin-left: -${(props) => props.theme.space.sm};
`;

export const Status = styled.p<CardDetailsType>`
  color: ${(props) =>
    props.type === 'hiddenFriend'
      ? props.theme.color.gray400
      : props.theme.learnerStatusColors[`${props.status}Outline`]};
`;
