import React from 'react';

import {
  ProgressBarProps,
  StepStatus,
} from '@/components/common/ProgressBar/types';

import Icon from '../Icon/Icon';
import { Line, Step, StepTitle, Wrapper } from './ProgressBar.styles';

const findStatus = (activeStep: number, index: number): StepStatus => {
  let result: StepStatus = 'active';
  if (activeStep > index + 1) {
    result = 'completed';
  } else if (activeStep < index + 1) {
    result = 'inactive';
  }
  return result;
};

const ProgressBar = ({
  stepTitles,
  activeStep,
  skipSteps = [],
  type = 'parent',
}: ProgressBarProps): JSX.Element => (
  <Wrapper>
    {stepTitles.map((step, index) => {
      return (
        <React.Fragment key={step}>
          <Step
            $skipped={skipSteps.includes(index)}
            $status={findStatus(activeStep, index)}
          >
            {activeStep <= index + 1 ? (
              index + 1
            ) : (
              <Icon
                type={skipSteps.includes(index) ? 'dash' : 'checkmark'}
                size="sm"
                color="white"
              />
            )}

            <StepTitle type={type}>{step}</StepTitle>
          </Step>

          {index < stepTitles.length - 1 && (
            <Line $status={findStatus(activeStep, index)} />
          )}
        </React.Fragment>
      );
    })}
  </Wrapper>
);

export default ProgressBar;
