import styled from 'styled-components';

import media from '@/modules/media';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    transform: scale(1, 1);
  }

  ${media.phone} {
    max-width: 143px;
  }
`;
