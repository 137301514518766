import media from '@/modules/media';
import { Button } from '@chakra-ui/react';
import { styled } from 'styled-components';

export const PhotoWrapper = styled.div<{ $isEmoji: boolean }>`
  width: 246px;
  height: 246px;
  background-color: ${(props) => props.theme.color.gray6};
  border-radius: 40%;
  margin-top: ${(props) => props.theme.space.xxl};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.$isEmoji && 'padding:  47px'};

  ${media.tabletLandscape} {
    margin-top: ${(props) => props.theme.space.lg};
  }

  & > img {
    transform: scale(1);
  }
`;

export const ImageInsertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleTabletWrapper = styled.div`
  padding: 43px 0 12px;
  display: none;

  ${media.tabletLandscape} {
    display: block;
  }
`;

export const ButtonCancel = styled(Button)`
  position: absolute !important;
  left: 16px;
  top: 36px;
  background-color: transparent !important;
  color: ${(props) => props.theme.color.gray900} !important;
  padding: 0 !important;
`;

export const Title = styled.p`
  font-size: ${(props) => props.theme.font.lg};
  margin-top: ${(props) => props.theme.space.xl};
  font-weight: 900;
  color: ${(props) => props.theme.color.gray900};
`;

export const Or = styled.p`
  font-size: ${(props) => props.theme.font.md};
  margin-top: ${(props) => props.theme.space.lg};
  margin-bottom: ${(props) => props.theme.space.lg};
`;
export const Note = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
`;
export const NoteText = styled.div`
  color: ${(props) => props.theme.color.primary700};
  font-size: ${(props) => props.theme.font.md};
  line-height: 24px;
  font-weight: 600;
`;
export const ErrorMessage = styled.p`
  margin-top: ${(props) => props.theme.space.md};
  font-size: ${(props) => props.theme.font.sm};
  color: ${(props) => props.theme.color.red};
`;
