import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: ${(props) => props.theme.space.lg} 56px;
  gap: ${(props) => props.theme.space.xl};
  text-align: start;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.md};
`;

export const SectionTitle = styled.p`
  font-size: ${(props) => props.theme.font.xl};
  color: ${(props) => props.theme.color.gray900};
  font-weight: 900;
  line-height: 32px;
`;

export const SectionSubtitle = styled.p`
  color: ${(props) => props.theme.color.gray700};
  font-weight: 900;
`;

export const SectionDescription = styled.p`
  color: ${(props) => props.theme.color.gray700};
  font-size: ${(props) => props.theme.font.md};
  line-height: 20px;
`;

export const List = styled.ul`
  padding-left: ${(props) => props.theme.space.xl};

  li {
    line-height: 20px;
    font-size: ${(props) => props.theme.font.md};
  }
`;

export const SectionLink = styled.a`
  color: ${(props) => props.theme.color.blue800};
  font-size: ${(props) => props.theme.font.md};
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
`;
