import styled from 'styled-components';

import { PhotoType } from '@/components/common/Avatar/types';

const borderLines: { [key: string]: string } = {
  xxs: '1px',
  xs: '2px',
  sm: '3px',
  md: '4px',
  mdl: '6px',
  lg: '8px',
};

type AvatarContainerType = PhotoType & {
  $isEmoji?: boolean;
};

export const AvatarContainer = styled.div<AvatarContainerType>`
  display: flex;
  object-fit: cover;
  transform: scale(1, 1);
  border-radius: ${(props) =>
    (props.radius === 'fullyCircled' && '50%') ||
    (props.radius === 'mediumCircled' && '43%') ||
    (props.radius === 'largeCircled' && '39%')};

  ${(props) =>
    props.$isEmoji
      ? ` background-color: rgba(255, 248, 236, 1);
      border: ${borderLines[props.size || 'md']} solid ${
          props.theme.learnerStatusColors[`${props.$status}Outline`]
        }};`
      : `
        background-color: ${
          props.theme.learnerStatusColors[`${props.$status}Outline`]
        };
        padding: ${borderLines[props.size || 'sm']};
   `}

  & img {
    ${(props) =>
      props.$isEmoji
        ? `
    transform: scale(0.65, 0.65);
  -ms-transform: scale(0.65, 0.65);
  -webkit-transform: scale(0.65, 0.65);`
        : `
    transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
    `}
  }
`;
