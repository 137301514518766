import { Fade } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import {
  AnnotatedText,
  ErrorWrapper,
  FullText,
  HelperText,
  HelperTextWrapper,
  InformativeText,
  LabelWrapper,
  Label,
  LabelInfoText,
  SuccessWrapper,
  Wrapper,
} from './FormElement.styles';

type HelperTextType = {
  text: string;
  annotatedText?: string;
};

type FormElementProps = {
  htmlFor: string;
  label?: string;
  labelInfoText?: string;
  error?: string;
  successMessage?: string;
  children?: ReactNode;
  forwardedRef?: React.RefObject<HTMLInputElement>;
  helperText?: HelperTextType[];
  informativeText?: string;
  arePointerEventsDisabled?: boolean;
  hasMaxWidth?: boolean;
  hasMinWidth?: boolean;
};

const FormElement = ({
  htmlFor,
  label,
  labelInfoText,
  error,
  successMessage,
  children,
  helperText,
  informativeText,
  forwardedRef,
  arePointerEventsDisabled,
  hasMaxWidth = false,
  hasMinWidth = false,
}: FormElementProps): JSX.Element => (
  <Wrapper
    ref={forwardedRef}
    $arePointerEventsDisabled={arePointerEventsDisabled}
    $hasMaxWidth={hasMaxWidth}
    $hasMinWidth={hasMinWidth}
  >
    <LabelWrapper>
      <Label htmlFor={htmlFor}>{label}</Label>
      <LabelInfoText>{labelInfoText}</LabelInfoText>
    </LabelWrapper>
    {children}
    {error && (
      <Fade in={Boolean(error)}>
        <ErrorWrapper>{error}</ErrorWrapper>
      </Fade>
    )}

    {successMessage && <SuccessWrapper>{successMessage}</SuccessWrapper>}
    {informativeText && <InformativeText>{informativeText}</InformativeText>}
    {helperText && (
      <HelperTextWrapper>
        {helperText.map((item: HelperTextType) => (
          <FullText key={item.annotatedText}>
            <HelperText key={item.text}>{item.text}</HelperText>
            <AnnotatedText>{item.annotatedText}</AnnotatedText>
          </FullText>
        ))}
      </HelperTextWrapper>
    )}
  </Wrapper>
);

export default FormElement;
