import styled from 'styled-components';

import {
  ProgressBarProps,
  StepStatus,
} from '@/components/common/ProgressBar/types';
import media from '@/modules/media';

type StepProps = {
  $status: StepStatus;
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StepTitle = styled.span<{ type?: ProgressBarProps['type'] }>`
  position: absolute;
  top: 35px;
  text-align: center;
  font-weight: 500;
  font-size: ${(props) => props.theme.font.md};
  color: ${(props) => props.theme.color.gray700};
  white-space: ${(props) => (props.type !== 'parent' ? 'wrap' : 'nowrap')};

  ${media.phone} {
    font-size: ${(props) => props.theme.font.md};
  }
`;

const backgroundColor = {
  active: 'primary700',
  completed: 'gray900',
  inactive: 'white',
};

export const Step = styled.div<StepProps & { $skipped: boolean }>`
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.font.md};
  border: 2px solid
    ${(props) =>
      props.$status === 'completed'
        ? props.theme.color.gray900
        : props.theme.color.primary700};
  border-radius: 50%;
  background-color: ${(props) =>
    props.theme.color[backgroundColor[props.$status]]};

  color: ${(props) =>
    props.$status === 'completed' || props.$status === 'active'
      ? props.theme.color.white
      : props.theme.color.primary700};

  ${(props) =>
    props.$skipped &&
    `
    border: 2px solid ${props.theme.color.gray500};
    background-color: ${props.theme.color.gray500};
  `}
`;

export const Line = styled.div<StepProps>`
  background-color: ${(props) =>
    props.$status === 'completed'
      ? props.theme.color.gray900
      : props.theme.color.primary600};
  height: 2px;
  width: 100%;
`;
