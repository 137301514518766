/* eslint-disable react/no-unescaped-entities */

import {
  List,
  Section,
  SectionDescription,
  SectionLink,
  SectionSubtitle,
  SectionTitle,
  Wrapper,
} from './Terms&Policy.styles';

export const Terms = () => {
  return (
    <Wrapper>
      <Section>
        <SectionDescription>
          Study HallⓇ Ltd. is an online learning platform for use at home and in
          schools. Study HallⓇ uses artificial intelligence and high-quality
          curriculum content.
          <br />
          These Terms of Use tell you the rules under which you can access and
          use the Study HallⓇ platform at SectionLink{' '}
          <SectionLink href="https://studyhall.ai" target="_blank">
            www.studyhall.ai
          </SectionLink>
          .
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>
          By using the Study HallⓇ platform you accept these terms
        </SectionTitle>
        <List>
          <li>
            Please read these terms carefully before you use the Study HallⓇ
            platform or submit your order to us. If you do not agree to these
            terms, you must not use the Study HallⓇ platform. We recommend that
            you print a copy of these terms for future reference.
          </li>
          <li>There are other terms that may apply to you</li>
          <li>
            These Terms of Use refer to the following additional terms, which
            also apply to your use of our platform and services
          </li>
          <li>
            Our Privacy Policy, which sets out the terms on which we process any
            personal data we collect from you, or that you provide to us. By
            using the Study HallⓇ platform, you consent to such processing and
            you warrant that all data provided by you is accurate.
          </li>
          <li>
            Our Legal Statement outlines our copyright and Trademark policy.
          </li>
        </List>
      </Section>

      <Section>
        <SectionTitle>Are you a business customer or a consumer? </SectionTitle>
        <List>
          <li>
            In some areas you will have different rights under these Terms of
            Use depending on whether you are a business or consumer. You are a
            consumer if: you are an individual; or you are buying products from
            us wholly or mainly for your personal use (not for use in connection
            with your trade, business, craft or profession).
          </li>
          <li>Information about us and how to contact us</li>
        </List>
      </Section>

      <Section>
        <SectionTitle>Who we are </SectionTitle>
        <SectionDescription>
          The Study HallⓇ platform and associated services are operated by Study
          HallⓇ Limited (Company no. 13596046) of 71-75 Shelton Street, Covent
          Garden, London, WC2H 9JQ, TN13 1LZ
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>How to contact us</SectionTitle>
        <List>
          <li>
            You can contact us by writing at 1st Formations, 71-75 Shelton
            Street, Greater London, WC2H 9JQ, UNITED KINGDOM and by email at{' '}
            <SectionLink href="mailto:contact@studyhall.ai">
              contact@studyhall.ai
            </SectionLink>
          </li>
          <li>
            How we may contact you. If we have to contact you we will do so by
            telephone or by writing to you at the email address or postal
            address you provided to us in your order.
          </li>
        </List>
      </Section>

      <Section>
        <SectionTitle>1. Definitions</SectionTitle>
        <SectionDescription>
          1.1 In these Terms of Use the following terms shall have the meanings
          assigned to them below:
        </SectionDescription>
        <List>
          <li>
            "Effective Date" means the date we issue you an email accepting your
            order for access to the Platform and the Licensed Materials;
          </li>
          <li>
            "Information" means any and all confidential or proprietary
            documentation, materials, software, code and information of one
            Party and disclosed to or otherwise obtained by the other Party in
            connection with these Terms of Use;
          </li>
          <li>
            "Intellectual Property" means any and all intellectual property
            rights including patents, trademarks, design rights, copyright,
            rights in data and databases, domain names, topography rights, and
            all similar rights (whether or not registered or capable of
            registration and whether subsisting in the United Kingdom or any
            other part of the world) together with any and all goodwill relating
            or attached thereto, the right to apply for registration of and/or
            register such rights and all extensions and renewals thereof;
          </li>
          <li>
            "Licensed Materials" means the content available on the Platform
            which you have purchased access to from us including, text,
            information, data, practice questions, interactive features, images,
            audio or video materials in digital form as described in your online
            account;
          </li>
          <li>
            "Parties" means you and us and “Party” shall be construed
            accordingly;
          </li>
          <li>
            "Platform" means the interactive online platform provided by us to
            you when you subscribe for access to the Licensed Materials;
          </li>
          <li>
            "Software" means our software provided as part of the Platform,
            including any updates and modifications made available from time to
            time by us;
          </li>
          <li>
            "Subscription Fees" means the subscription fees payable by you to us
            for the User Subscriptions as set out in your order form;
          </li>
          <li>"Term" means has the meaning set out in clause 18.1;</li>
          <li>
            "User Subscriptions" means the User subscriptions purchased by which
            entitle Users to access and use the Platform and the Licensed
            Materials in accordance with these Terms of Use;
          </li>
          <li>
            "Users" means the users who are authorised by you to use the
            Platform and access and use the Licensed Materials;
          </li>
          <li>
            "Virus" means any thing or device (including any software, code,
            file or programme) which may: prevent, impair or otherwise adversely
            affect the operation of any computer software, hardware or network,
            any telecommunications service, equipment or network or any other
            service or device; prevent, impair or otherwise adversely affect
            access to or the operation of any programme or data, including the
            reliability of any programme or data (whether by re-arranging,
            altering or erasing the programme or data in whole or part or
            otherwise); or adversely affect the user experience, including
            worms, trojan horses, viruses and other similar things or devices;
          </li>
          <li>
            "Working Day" means any day falling on or between Monday to Friday,
            excluding all public and bank holidays in England and Wales; and
          </li>
          <li>
            "you" "your" means a parent, pupil, teacher, tutor, or any other
            individual who accesses our platform and/or is authorised to use the
            Study HallⓇ platform.
          </li>
        </List>
      </Section>

      <Section>
        <SectionTitle>2. Our Contract with you</SectionTitle>
        <SectionDescription>
          2.1 We permit use of the Platform and the Licensed Materials by you on
          the basis of these Terms of Use. By subscribing, you agree to be bound
          by the terms of these Terms of Use.
        </SectionDescription>
        <SectionDescription>
          2.2 Our acceptance of your order will take place when we email you to
          accept it, at which point a contract will come into existence between
          us and you.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>3. Licensed Materials</SectionTitle>
        <SectionDescription>
          3.1 Subject to the restrictions and your obligations set out in these
          Terms of Use, we hereby grant to you a non-transferable, non-exclusive
          licence to permit the Users to use the Licensed Materials commencing
          on the Effective Date during the Term. The licence granted to you is
          strictly limited to use of the Licensed Materials (both in electronic
          and hard copy form) by Users.
        </SectionDescription>
        <SectionDescription>
          3.2 You shall not grant sub-licences, in whole or in part, of any of
          the Licensed Materials, and you may not share Licensed Materials with
          anyone other than Users.
        </SectionDescription>
        <SectionDescription>
          3.3 We may require the removal of, or editorial revisions to, any of
          the Licensed Materials licensed to you under these Terms of Use at any
          time.
        </SectionDescription>
        <SectionDescription>
          3.4 Any and all rights in, or in relation to, the Licensed Materials
          that are not expressly granted to you under these Terms of Use, and
          any other rights (whether know now, or created later, and whether or
          not in contemplation of the parties at the time of these Terms of Use)
          are expressly reserved by us.
        </SectionDescription>
        <SectionDescription>
          3.5 We may require you to cease all use of any of the Licensed
          Materials if we reasonably believe that your use of this Licensed
          Materials infringes the Intellectual Property rights of any third
          party, or breaches any applicable law or regulation. In this instance,
          we may, at our option either: <br />
          (a) provide you with alternative Licensed Materials so as to avoid the
          infringement; or <br />
          (b) terminate these Terms of Use immediately on written notice in
          respect of the affected Licensed Materials.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>4. Licence to use the Platform</SectionTitle>
        <SectionDescription>
          4.1 Subject to the restrictions and your obligations set out in these
          Terms of Use, we hereby grant to you a non-exclusive, non-transferable
          right to permit the Users to use the Platform commencing on the
          Effective Date during the Term.
        </SectionDescription>
        <SectionDescription>
          4.2 Except as may be allowed by any applicable law which is incapable
          of exclusion by agreement between the Parties, you may not nor permit
          others to: <br />
          (a) attempt to copy, modify, duplicate, create derivative works from,
          frame, mirror, republish, download, display, transmit, or distribute
          all or any portion of the Software in any form or media or by any
          means; <br />
          (b) rent, lease, sublicense, sell, assign, pledge, transfer or
          otherwise dispose of the Software, on a temporary or permanent basis;
          <br />
          (c) translate, reverse engineer, decompile, disassemble, unbundle,
          modify or create derivative works based on the Software, except as
          expressly permitted by law; <br />
          (d) vary, delete or obscure any notices of proprietary rights or any
          product identification or restrictions on or in the Software, the
          Platform and the Licensed Materials; or <br />
          (e) access all or any part of the Platform and/or the Licensed
          Materials in order to build a product or service which competes with
          the Platform and/or the Licensed Materials.
        </SectionDescription>
        <SectionDescription>
          4.3 You undertake to use all reasonable endeavours to prevent any
          unauthorised access to, or use of, the Platform and/or the Licensed
          Materials and, in the event of any such unauthorised access or use,
          promptly notify us.
        </SectionDescription>
        <SectionDescription>
          4.4 All rights that are not expressly or specifically granted in these
          Terms of Use are reserved by us.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>5. Users</SectionTitle>
        <SectionDescription>
          5.1 In relation to the Users, you undertake that: <br />
          (a) you will not allow or suffer any User Subscription to be used by
          more than one individual User unless it has been reassigned in its
          entirety to another individual User, in which case the prior User
          shall no longer have any right to access or use the Platform and/or
          the Licensed Materials; <br />
          (b) each User shall keep a secure password for his use of the Platform
          and the Licensed Materials;
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>6. Provision of the Platform</SectionTitle>
        <SectionDescription>
          6.1 We shall use reasonable endeavours to make the Platform and the
          Licensed Materials available 24 hours a day, seven days a week, except
          for planned maintenance and unscheduled maintenance.
        </SectionDescription>
        <SectionDescription>
          6.2 We reserve the right at any time to make any modification, change
          or addition to, or replacement of, the Platform where this is required
          to conform with any applicable statutory requirements.
        </SectionDescription>
        <SectionDescription>
          6.3 We shall not be liable for any failure to provide the Platform in
          accordance with these Terms of Use to the extent that such failure is
          caused directly or indirectly by your negligence or breach of any term
          of these Terms of Use.
        </SectionDescription>
        <SectionDescription>
          6.4 We do not guarantee that the Study HallⓇ platform will be secure
          or free from bugs or viruses. You are responsible for configuring your
          information technology, computer programmes and platform to access the
          Study HallⓇ platform. You should use your own virus protection
          software.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>7. Acceptable Use</SectionTitle>
        <SectionDescription>
          7.1 You shall not access, store, distribute or transmit any Viruses in
          your use of the Platform and the Licensed Materials.
        </SectionDescription>
        <SectionDescription>
          7.2 We may from time to time provide interactive services on the
          Platform. We will use reasonable endeavours to assess any possible
          risks for users from third parties when they use any interactive
          service provided on the Platform and we will decide in each case
          whether it is appropriate to use moderation of the relevant service
          (including what kind of moderation to use) in the light of those
          risks. However, we are under no obligation to oversee, monitor or
          moderate any interactive service on the Platform and we expressly
          exclude all liability for any loss or damage arising from the use of
          any interactive service by a User in contravention of our content
          standards, whether the service is moderated or not.
        </SectionDescription>
        <SectionDescription>
          7.3 The following content standards apply to any and all material
          which you and Users contribute to the Platform and to any interactive
          services associated with it. You must comply with the spirit and the
          letter of the following standards. The standards apply to each part of
          any contribution as well as to its whole.
        </SectionDescription>
        <SectionDescription>
          7.4 Contributions must: be accurate (where they state facts); be
          genuinely held (where they state opinions); and comply with applicable
          law.
        </SectionDescription>
        <SectionDescription>
          7.5 Contributions must not: contain any material which is defamatory
          of any person; contain any material which is obscene, offensive,
          hateful or inflammatory; promote sexually explicit material; promote
          violence; promote discrimination based on race, sex, religion,
          nationality, disability, sexual orientation or age; infringe any
          copyright, database right or trade mark of any other person; be likely
          to deceive any person; be made in breach of any legal duty owed to a
          third party, such as a contractual duty or a duty of confidence;
          promote any illegal activity; be threatening, abuse or invade
          another's privacy, or cause annoyance, inconvenience or needless
          anxiety; be likely to harass, upset, embarrass, alarm or annoy any
          other person; be used to impersonate any person, or to misrepresent
          identity or affiliation with any person; give the impression that they
          emanate from us, if this is not the case; or advocate, promote or
          assist any unlawful act such as (by way of example only) copyright
          infringement or computer misuse.
        </SectionDescription>
        <SectionDescription>
          7.6 We will determine, in our sole discretion, whether there has been
          a breach of these acceptable use standards through your and/or any
          User's use of the Platform. When a breach of these standards has
          occurred, we may take such action as we deem appropriate.
        </SectionDescription>
        <SectionDescription>
          7.7 We have the right to remove any posting you and/or any User makes
          on the Platform if, in our opinion, your and/or any Users post does
          not comply with the standards set out above.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>
          8. Exercising your right to change your mind if you are a consumer
        </SectionTitle>
        <SectionDescription>
          8.1 If you are a consumer then for most products bought online you
          have a legal right to change your mind within 14 days and receive a
          refund. These rights, under the Consumer Contracts Regulations 2013,
          are explained in more detail below.
        </SectionDescription>
        <SectionDescription>
          8.2 Your right as a consumer to change your mind does not apply in
          respect of digital products after you have started to download, access
          or stream the product.
        </SectionDescription>
        <SectionDescription>
          8.3 You have 14 days after the day we email you to confirm we accept
          your order, or, if earlier, until you start downloading, accessing or
          streaming. If we make the Platform and/or the Licensed Materials
          available to you immediately, and you agreed to this, you will not
          have a right to change your mind.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>9. Third Party Websites and Internet Use</SectionTitle>
        <SectionDescription>
          9.1 Where the Platform or any Licensed Materials contain links to
          other sites and resources provided by third parties, these links are
          provided for your and the Users' information only. Such links should
          not be interpreted as approval by us of those linked websites or
          information you or Users may obtain from them. We have no control over
          the contents of those sites or resources.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>10. Warranties</SectionTitle>
        <SectionDescription>
          10.1 We undertake that the Platform will be provided and the Licensed
          Materials have been developed using reasonable skill and care.
        </SectionDescription>
        <SectionDescription>
          10.2 The undertaking at clause 10.1 shall not apply to the extent of
          any non-conformance which is caused by use of the Platform and/or the
          Licensed Materials contrary to our instructions, or modification or
          alteration of the Platform and/or the Licensed Materials by any party
          other than us or our duly authorised contractors or agents. If the
          Platform and/or the Licensed Materials do not conform with the
          foregoing undertaking, we will, at our expense, use all reasonable
          commercial endeavours to correct any such non-conformance promptly, or
          provide you with an alternative means of accomplishing the desired
          performance. Notwithstanding the foregoing, we: <br />
          (a) do not warrant that your use of the Platform will be uninterrupted
          or error-free; or that the Platform, the Licensed Materials and/or any
          information obtained by you through the Platform will meet the your
          requirements; <br />
          (b) do not suggest or imply that the use of the Platform and/or the
          Licensed Materials will result in a pass in any exam nor acceptance at
          any school; and <br />
          (c) are not responsible for any delays, delivery failures, or any
          other loss or damage resulting from the transfer of data over
          communications networks and facilities, including the internet, and
          you acknowledge that the Platform and access to the Licensed Materials
          may be subject to limitations, delays and other problems inherent in
          the use of such communications facilities.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>11. Your Obligations</SectionTitle>
        <SectionDescription>
          10.1 We undertake that the Platform will be provided and the Licensed
          Materials have been developed using reasonable skill and care.
        </SectionDescription>
        <SectionDescription>
          10.2 The undertaking at clause 10.1 shall not apply to the extent of
          any non-conformance which is caused by use of the Platform and/or the
          Licensed Materials contrary to our instructions, or modification or
          alteration of the Platform and/or the Licensed Materials by any party
          other than us or our duly authorised contractors or agents. If the
          Platform and/or the Licensed Materials do not conform with the
          foregoing undertaking, we will, at our expense, use all reasonable
          commercial endeavours to correct any such non-conformance promptly, or
          provide you with an alternative means of accomplishing the desired
          performance. Notwithstanding the foregoing, we: <br />
          (a) do not warrant that your use of the Platform will be uninterrupted
          or error-free; or that the Platform, the Licensed Materials and/or any
          information obtained by you through the Platform will meet the your
          requirements; <br />
          (b) do not suggest or imply that the use of the Platform and/or the
          Licensed Materials will result in a pass in any exam nor acceptance at
          any school; and
          <br />
          (c) are not responsible for any delays, delivery failures, or any
          other loss or damage resulting from the transfer of data over
          communications networks and facilities, including the internet, and
          you acknowledge that the Platform and access to the Licensed Materials
          may be subject to limitations, delays and other problems inherent in
          the use of such communications facilities.
          <br />
          (d) not resell the Licensed Materials;
          <br />
          (e) only permit the use of the Platform and the Licensed Materials by
          Users and shall not permit the use of the Platform or the Licensed
          Materials by any third party;
          <br />
          (f) ensure that the Licensed Materials once downloaded are kept secure
          and shall prevent, and take prompt and proper remedial action against,
          unauthorised access, copying, modification, storage, reproduction,
          display and distribution of the Licensed Materials;
          <br />
          (g) not and shall procure Users shall not display, upload or input any
          Licenced Materials to websites or online platforms (your own, public
          or commercial) without our prior written authorisation;
          <br />
          (h) ensure that the Users use the Platform and the Licensed Materials
          in accordance with the terms and conditions of these Terms of Use and
          shall be responsible for any User's breach of these Terms of Use
          including by ensuring Users do not disclose the Licensed Materials to
          any third party;
          <br />
          (i) be solely responsible for procuring and maintaining your network
          connections and telecommunications links from your systems to our data
          centres, and all problems, conditions, delays, delivery failures and
          all other loss or damage arising from or relating to your network
          connections or telecommunications links or caused by the internet; and
          <br />
          (j) provide us with up-to-date information, co-operation and support
          as we shall reasonably require pursuant to these Terms of Use.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>12. Charges and Payment</SectionTitle>
        <SectionDescription>
          12.1 You shall pay the Subscription Fees to us in accordance with this
          clause 12.
        </SectionDescription>
        <SectionDescription>
          12.2 Unless otherwise agreed with us, you shall on the date you order
          access to the Platform and the Licensed Materials provide to us valid,
          up-to-date and complete credit or debit card details or approved
          purchase order information acceptable to us and any other valid,
          up-to-date and complete contact and billing details and, if you
          provide: <br />
          (a) your credit or credit card details to us, you hereby authorises us
          to bill such credit or debit card on the Effective Date for the
          Subscription Fee payable in respect of the Term; <br />
          (b) your approved purchase order information to us, we shall invoice
          you on the Effective Date for the Subscription Fees payable in respect
          of the Term and you shall pay each invoice within 7 days after the
          date of such invoice.
        </SectionDescription>
        <SectionDescription>
          12.3 All amounts and fees stated in these Terms of Use: <br />
          (a) are inclusive of Value Added Tax and any other applicable taxes,
          duties and assessments which shall be payable by you in the manner
          prescribed by law; <br />
          (b) shall be payable in pounds sterling; and <br />
          (c) are non-cancellable and non-refundable.
        </SectionDescription>
        <SectionDescription>
          12.4 If you fail to make any payment due to us by the due date for
          payment then, without prejudice to our other rights and remedies, we
          may: <br />
          (a) where you are a business customer, charge interest on the overdue
          amount at four percent (4%) above the official interest rate of the
          Bank of England. Such interest shall accrue on a daily basis from the
          due date of payment until the date of actual payment of the overdue
          amount, whether before or after judgment. You shall pay the interest
          immediately on demand by us; and/or <br />
          (b) suspend your and each User's access to the Platform and the
          Licensed Materials and/or any other services provided under on in
          connection with these Terms of Use (or any part of them).
        </SectionDescription>
        <SectionDescription>
          12.5 If you are a business customer you must pay all amounts due to us
          under these Terms of Use in full without any set-off, counterclaim,
          deduction or withholding (other than any deduction or withholding of
          tax as required by law).
        </SectionDescription>
        <SectionDescription>
          12.6 If you have signed up to a subscription package, at the end of
          the term length of the package, your subscription package will
          automatically be rolled onto a monthly subscription at the same
          monthly rate that you were paying for the subscription package.
        </SectionDescription>
        <SectionDescription>
          12.7 Study HallⓇ Ltd. offers various subscription packages for
          non-business customers, offering a range of price versus flexibility,
          so that non-business customers have choice: <br />
          (a) Monthly rolling subscriptions – for these package types, payment
          is collected on the date you subscribe, following any free trial
          period. The subscription continues to roll on a monthly basis until
          you cancel the subscription. <br />
          (b) Annual subscriptions – for these package types, full payment is
          required upfront for the minimum term length (12 months) following any
          free trial period. The subscription continues to roll on a monthly
          basis after the minimum term has expired until you cancel the
          subscription.
        </SectionDescription>
        <SectionDescription>
          12.8 Customers can cancel a subscription at any time. <br />
          (a) Once a customer has cancelled a subscription, they will not be
          billed further by Study HallⓇ Ltd. for the cancelled subscription.
          <br />
          (b) Once a customer has cancelled their subscription, they will
          continue to receive access to the Platform for the remainder of the
          period that they have already paid for. <br />
          (c) A customer has no right to any refund of a subscription payment
          taken before the time that they cancel their subscription. p: b For
          the avoidance of doubt, if a customer is on a monthly subscription
          plan and cancels 15 days (of 30 days) into their latest subscription
          period, the customer will retain access to the Platform for the
          remaining 15 days still covered by their last payment. They will not
          be entitled to a refund of any amount of their last payment. Once the
          remainder of the term length of the subscription is finished, the
          customer will automatically lose access to the Platform; they will not
          be charged further.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>13. Intellectual Property</SectionTitle>
        <SectionDescription>
          13.1 All of our Information and all Intellectual Property and any
          other rights in the Software and in or arising from the Platform, the
          Licensed Materials and/or any other services provided under or in
          connection with these Terms of Use shall be and shall remain at all
          times our exclusive property or of our licensors and you shall acquire
          no right, title or interest in or to the same and shall use such items
          solely as permitted by the terms of these Terms of Use.
        </SectionDescription>
        <SectionDescription>
          13.2 Even if you have subscribed for access to the Platform and the
          Licensed Materials you still may not save and print downloadable
          Licensed Materials during the Term. You may not share or distribute
          any Licensed Materials and you may not share Users’ logins with any
          other party.Our status (and that of any identified contributors) as
          the authors of content on the Study HallⓇ platform must always be
          acknowledged.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>14. Confidentiality</SectionTitle>
        <SectionDescription>
          14.1 Subject to the remainder of this Clause 14, neither you nor we
          shall, without the other Party’s prior written consent, disclose to
          any third party Information which comes to that Party’s attention
          pursuant to these Terms of Use. Each Party shall only use the
          Information of the other Party to exercise its rights and/or perform
          its obligations under these Terms of Use.
        </SectionDescription>
        <SectionDescription>
          14.2 You agree that we may disclose your Information to any relevant
          third party to the extent reasonably required by such third party in
          order to allow provision of the Platform, the Licensed Materials
          and/or any other services provided under or in connection with these
          Terms of Use.
        </SectionDescription>
        <SectionDescription>
          14.3 The provisions of Clause 15.1 shall not apply to information
          which: <br />
          (a) is in or comes into the public domain otherwise than by breach of
          these Terms of Use, except that any compilation of otherwise public
          information in a form not publically known shall nevertheless be
          treated as confidential Information; <br />
          (b) is in the other Party’s possession prior to the commencement of
          negotiations for these Terms of Use as shown by written evidence that
          predates the date of such negotiations; <br />
          (c) is or was lawfully received from a third party not under an
          obligation of confidentiality in respect of the same as shown by
          written evidence that predates the date of these Terms of Use; <br />
          (d) was developed independently of and without reference to the other
          Party’s Information; or <br />
          (e) is required to be disclosed under operation of law, by court order
          or by any regulatory body of competent jurisdiction (but then only to
          the extent and for the purpose required), in which case each Party
          shall promptly notify the other Party of any such disclosure
          requirement.
        </SectionDescription>
        <SectionDescription>
          14.4 We shall be entitled to publicise that you have licensed use of
          the Platform and the Licensed Materials from us in our advertising or
          promotional materials (including in case studies), press releases,
          tenders, proposal, speeches, articles and other similar materials.
        </SectionDescription>
        <SectionDescription>
          14.5 We shall be entitled to divulge your Information to our
          employees, agents, directors, officers, authorised sub-contractors,
          professional advisors and consultants who have a need to know the same
          in connection with these Terms of Use.
        </SectionDescription>
        <SectionDescription>
          14.6 The restrictions contained in this Clause 15 shall continue to
          apply after termination or expiry of these Terms of Use without limit
          in time.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>
          15. Our responsibility for loss or damage suffered if you are a
          consumer
        </SectionTitle>
        <SectionDescription>
          15.1 If we fail to comply with these Terms of Use, we are responsible
          for loss or damage you suffer that is a foreseeable result of our
          breaking these Terms of Use or our failing to use reasonable care and
          skill. Loss or damage is foreseeable if either it is obvious that it
          will happen or if, at the time the contract was made, both we and you
          knew it might happen.
        </SectionDescription>
        <SectionDescription>
          15.2 We do not exclude or limit in any way our liability to you where
          it would be unlawful to do so. This includes liability for death or
          personal injury caused by our negligence or the negligence of our
          employees, agents or subcontractors; for fraud or fraudulent
          misrepresentation; for breach of your legal rights in relation to the
          provision of the Platform and the Licensed Materials.
        </SectionDescription>
        <SectionDescription>
          15.3 If you are a consumer we only supply the Platform and Licensed
          Materials to you for domestic and private use. If you use the products
          for any commercial or business purpose our liability to you will be
          limited as set out in clause 16.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>16. Liability</SectionTitle>
        <SectionDescription>
          16.1 This clause 15 sets out our entire financial liability (including
          any liability for the acts or omissions of its employees, agents and
          subcontractors) to you: <br />
          (a) arising under or in connection with these Terms of Use; <br />
          (b) in respect of any use made by you of the Platform, the Licensed
          Materials and/or any other services provided under or in connection
          with these Terms of Use or any part of them; and <br />
          (c) in respect of any representation, statement or tortious act or
          omission (including negligence) arising under or in connection with
          these Terms of Use.
        </SectionDescription>
        <SectionDescription>
          16.2 Except as expressly and specifically provided in these Terms of
          Use: <br />
          (a) you assume sole responsibility for results obtained from the use
          of the Platform and the Licensed Materials by you, and for conclusions
          drawn from such use; <br />
          (b) all warranties, representations, conditions and all other terms of
          any kind whatsoever implied by statute or common law are, to the
          fullest extent permitted by applicable law, excluded from these Terms
          of Use; and <br />
          (c) the Platform and the Licensed Materials are provided to you on an
          "as is" basis.
        </SectionDescription>
        <SectionDescription>
          16.3 Nothing in these Terms of Use shall exclude or limit our
          liability for: <br />
          (a) death or personal injury caused by its (or its employees’, agents’
          or contractors’) negligence; and <br />
          (b) fraud or fraudulent misrepresentation; and <br />
          (c) any other liability the exclusion or limitation of which is not
          permitted by law.
        </SectionDescription>
        <SectionDescription>
          16.4 Subject to clause 17.2 and clause 17.3: <br />
          (a) We shall not be liable whether in tort (including for negligence
          or breach of statutory duty), contract, misrepresentation, restitution
          or otherwise for any: loss of profits; loss of business; depletion of
          goodwill and/or similar losses; or loss or corruption of data or
          information; or pure economic loss; or for any special, indirect or
          consequential loss, costs, damages, charges or expenses however
          arising under these Terms of Use; and <br />
          (b) Our total aggregate liability in contract, tort (including
          negligence or breach of statutory duty), misrepresentation,
          restitution or otherwise, arising in connection with the performance
          or contemplated performance of these Terms of Use shall be limited to
          the Subscription Fees payable by you under these Terms of Use during
          the twelve (12) month period prior to the date the liability first
          arose or where the liability arises after the expiry or termination of
          these Terms of Use, to the Subscription Fees payable by you to us in
          the twelve (12) month period prior to expiry or termination of these
          Terms of Use.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>17. Term and Termination</SectionTitle>
        <SectionDescription>
          17.1 These Terms of Use shall, unless otherwise terminated in
          accordance with these Terms of Use, commence on the Effective Date and
          for the subscription term selected by you when ordering access to the
          Platform and Licensed Materials (the "Term").
        </SectionDescription>
        <SectionDescription>
          17.2 A Party shall have the right to terminate these Terms of Use at
          any time on immediate notice to the other Party in the event that the
          other Party: <br />
          (a) fails to pay any amount due under these Terms of Use on the due
          date for payment; <br />
          (b) commits an irremediable material breach of these Terms of Use,
          persistently repeats a remediable material breach or commits any
          remediable material breach and fails to remedy it within fourteen (14)
          days of receipt of the notice of the breach requiring remedy of the
          same; or <br />
          (c) where the other party is a company, the other party takes any step
          or action in connection with its entering administration, provisional
          liquidation or any composition or arrangement with its creditors
          (other than in relation to a solvent restructuring), being wound up
          (whether voluntarily or by order of the court, unless for the purpose
          of a solvent restructuring), having a receiver appointed to any of its
          assets or ceasing to carry on business or, if the step or action is
          taken in another jurisdiction, in connection with any analogous
          procedure in the relevant jurisdiction; <br />
          (d) where the other party is a company, the other party suspends, or
          threatens to suspend, or ceases or threatens to cease to carry on all
          or a substantial part of its business; or <br />
          (e) the other party's financial position deteriorates to such an
          extent that in the terminating party's opinion the other party's
          capability to adequately fulfill its obligations under these Terms of
          Use has been placed in jeopardy. acknowledged.
        </SectionDescription>
        <SectionDescription>
          17.3 We may terminate these Terms of Use (or any part of it) and/or
          access to the Platform and/or the Licensed Materials (or any part of
          them): <br />
          (a) by serving written notice on you with immediate effect, if
          termination is required for legal or regulatory reasons; <br />
          (b) on serving fourteen (14) days prior written notice to you if we or
          our third party suppliers no longer operate or provide any or all of
          the products or services used in relation to the Platform or the
          Licensed Materials or intends to cease operating or providing any or
          all of such products or services in the immediate future.
        </SectionDescription>
        <SectionDescription>
          17.4 The termination of these Terms of Use shall be without prejudice
          to the accrued rights and liabilities of either Party subsisting under
          these Terms of Use prior to termination.
        </SectionDescription>
        <SectionDescription>
          17.5 We may at our sole discretion suspend immediately the provision
          of the Platform, access to the Licensed Materials and/or any other
          services provided under these Terms of Use (or any part of them) until
          further notice on notifying you either orally (confirming such
          notification in writing) or in writing if we are entitled to terminate
          these Terms of Use. Any suspension shall not exclude our right
          subsequently to terminate these Terms of Use.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>18. Consequences of Termination</SectionTitle>
        <SectionDescription>
          18.1 Upon termination of these Terms of Use for any reason, you shall:
          <br />
          (a) immediately cease to make use of the Study HallⓇ Platform and the
          Licensed Materials; <br />
          (b) immediately pay any outstanding sums due under these Terms of Use;
          and <br />
          (c) either return or destroy all of our Information, the Licensed
          Materials or any document containing part thereof, together with all
          copies of the Information and the Licensed Materials (including all
          electronic copies and hard copies) and shall on reasonable request
          provide written confirmation that such steps have been taken.
        </SectionDescription>
        <SectionDescription>
          18.2 Any rights, remedies, obligations or liabilities of the Parties
          that have accrued up to the date of termination, including the right
          to claim damages in respect of any breach of these Terms of Use which
          existed at or before the date of termination shall not be affected or
          prejudiced.
        </SectionDescription>
        <SectionDescription>
          18.3 Any terms and conditions forming part of these Terms of Use which
          are agreed by the Parties to survive termination or which by their
          nature are to survive termination, shall survive and continue in full
          force and effect.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>19. Force Majeure</SectionTitle>
        <SectionDescription>
          19.1 We shall have no liability to you under these Terms of Use if we
          are prevented from or delayed in performing its obligations under
          these Terms of Use, or from carrying on our business, by acts, events,
          omissions or accidents beyond its reasonable control, including,
          without limitation, strikes, lock-outs or other industrial disputes
          (whether involving our workforce or any other party), failure of a
          utility service or transport or telecommunications network, act of
          God, war, riot, civil commotion, malicious damage, compliance with any
          law or governmental order, rule, regulation or direction, accident,
          breakdown of plant or machinery, fire, flood, storm or default of
          suppliers or subcontractors.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>20. Notices</SectionTitle>
        <SectionDescription>
          20.1 Notices sent in respect of any matter arising in respect of these
          Terms of Use shall be in writing and must be sent either by: <br />
          (a) first class post; or <br />
          (b) delivered by hand; or <br />
          (c) email.
        </SectionDescription>
        <SectionDescription>
          20.2 A notice shall be sent or delivered to the address or email
          address specified by you in your order for access to the Platform and
          the Licensed Materials. Notice shall be sent by email to{' '}
          <SectionLink href="mailto:contact@studyhall.ai">
            contact@studyhall.ai
          </SectionLink>
        </SectionDescription>
        <SectionDescription>
          20.3 Notice is deemed given: <br />
          (a) in the case of hand delivery – at the time the delivery is made;
          <br />
          (b) in the case of posting– two (2) Working Days after the notice is
          posted; <br />
          (c) in the case of email – one (1) Working Day after transmission.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>21. General</SectionTitle>
        <SectionDescription>
          21.1 You shall not assign, purport to assign or otherwise transfer
          these Terms of Use and/or any of its obligations thereunder, in whole
          or in part, without our prior written consent.
        </SectionDescription>
        <SectionDescription>
          21.2 If we do not insist immediately that you do anything you are
          required to do under these Terms of Use, or if we delay in taking
          steps against you in respect of your breaking these Terms of Use, that
          will not mean that you do not have to do those things and it will not
          prevent us taking steps against you at a later date. For example, if
          you miss a payment and we do not chase you but we continue to provide
          the Platform and Licensed Materials, we can still require you to make
          the payment at a later date.
        </SectionDescription>
        <SectionDescription>
          21.3 Headings in these Terms of Use are for ease of reference only and
          do not limit the scope of the relevant section.
        </SectionDescription>
        <SectionDescription>
          21.4 We may at any time assign, transfer, charge, sub-contract or deal
          in any other manner with all or any of its rights or obligations under
          these Terms of Use.
        </SectionDescription>
        <SectionDescription>
          21.5 If any part of these Terms of Use is held unlawful, invalid or
          unenforceable, that part shall be considered struck out and the
          remainder of these Terms of Use shall remain in full force and effect.
          We and you shall work together in good faith to agree to an
          enforceable replacement provision capturing the spirit of the
          original.
        </SectionDescription>
        <SectionDescription>
          21.6 If you are a business customer, these Terms of Use, and any
          documents referred to in it, constitute the whole agreement between
          the parties and supersede any previous arrangement, understanding or
          agreement between them relating to the subject matter they cover. Each
          of the parties acknowledges and agrees that in entering into these
          Terms of Use it does not rely on any undertaking, promise, assurance,
          statement, representation, warranty or understanding (whether in
          writing or not) of any person (whether party to these Terms of Use or
          not) relating to the subject matter of these Terms of Use, other than
          as expressly set out in these Terms of Use.
        </SectionDescription>
        <SectionDescription>
          21.7 These Terms of Use are between you and us. No other person shall
          have any rights to enforce any of its terms.
        </SectionDescription>
        <SectionDescription>
          21.8 Which laws apply to this contract and where you may bring legal
          proceedings if you are a consumer. These Terms of Use are governed by
          English law and you can bring legal proceedings in respect of these
          Terms of Use in the English courts. If you live in Scotland you can
          bring legal proceedings in respect of these Terms of Use in either the
          Scottish or the English courts. If you live in Northern Ireland you
          can bring legal proceedings in respect of these Terms of Use in either
          the Northern Irish or the English courts.
        </SectionDescription>
        <SectionDescription>
          21.9 Which laws apply to this contract and where you may bring legal
          proceedings if you are a business. If you are a business, any dispute
          or claim arising out of or in connection with these Terms of Use
          between us or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with the law of England and Wales and the courts of
          England and Wales shall have exclusive jurisdiction to settle any such
          dispute or claim.
        </SectionDescription>
      </Section>

      <Section>
        <SectionDescription>
          Copyright © 2023 Study HallⓇ Ltd. All rights reserved.
        </SectionDescription>
        <br />
        <br />
      </Section>
    </Wrapper>
  );
};

export const Privacy = () => {
  return (
    <Wrapper>
      <Section>
        <SectionTitle>Introduction</SectionTitle>

        <SectionDescription>
          The web application is offered from the United Kingdom by Study HallⓇ
          Ltd. (referred to in this Notice as “our”, “us”, “Study HallⓇ” and
          “we”), a company registered in England and Wales. We are committed to
          protecting and respecting your privacy. This Privacy Notice (together
          with any other documents referred to herein) sets out the basis on
          which the personal data collected from you, or that you provide to Us,
          will be processed by Us in connection with Our web application and
          general enquiries processes. Please read the following carefully to
          understand Our views and practices regarding your personal data and
          how We will treat it. For the purpose of the General Data Protection
          Regulation (“GDPR”) the Data Controller is Study HallⓇ Ltd., where
          data is inputted directly by instructors, parents and learners; and
          the Data Processor is Study HallⓇ Ltd., where data is uploaded by a
          school (enterprise client).
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>Your Personal Information</SectionTitle>
        <SectionSubtitle>Information We collect from You</SectionSubtitle>
        <SectionDescription>
          We collect and process some or all of the following types of
          information from you:
        </SectionDescription>
        <List>
          <li>
            Information that you provide when you create a user account
            (customer, learner, instructor), including name, email address,
            telephone number, post address, age, current school, intended
            school, exam goals and exam date. Please note, when you create an
            account, you confirm that you have the authority and the consent of
            the relevant data subjects, or their parent, caregiver or guardian,
            to do so.
          </li>
          <li>
            Information that you provide when you submit an enquiry. This
            includes information provided through our enquiry form, via email,
            via phone and/or by any other method. In particular, We process
            personal details such as name, email address, telephone number,
            postal address, role, organisation you work for, and business
            requirements or any other details in the enquiry that you provide to
            Us.
          </li>
          <li>
            If you contact Us, We may keep a record of that correspondence.
          </li>
          <li>
            Details of all actions that you carry out through the use of the
            Services.
          </li>
          <li>
            Details of your visits to the Study HallⓇ platform including, but
            not limited to, traffic data, location data, weblogs and other
            communication data, the site that referred you to the Study Hall
            platform and the resources that you access.
            <br />
            <br />
          </li>
        </List>

        <SectionSubtitle>
          Information We Collect From Other Sources
        </SectionSubtitle>

        <SectionDescription>
          We have the facility to link the data you provide to Us, with other
          publicly available information about you that you have published on
          the Internet – this may include sources such as government
          departments, local authorities, LinkedIn and other social media
          profiles.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>Uses Made Of Your Information</SectionTitle>
        <SectionSubtitle>Lawful basis for processing</SectionSubtitle>
        <SectionDescription>
          We rely on legitimate interest as the lawful basis on which We collect
          and use your personal data. Our legitimate interests are the
          fulfilment of commercial arrangements with organisations interested in
          engaging Our business.
          <br />
        </SectionDescription>

        <SectionSubtitle>Purposes of processing</SectionSubtitle>
        <SectionDescription>
          We use information held about you in the following ways:
        </SectionDescription>
        <List>
          <li>
            On the basis that there is a contract between Study HallⓇ Ltd. and
            you. We process your data in order to fulfil this contract.
          </li>
          <li>
            Learner, parent and teacher information will be used to create
            individual logins for each user, allowing them to access Our
            platform. This will enable teachers to explore content, assign tasks
            and review progress. It will enable parents to review progress and
            amend Learner and parent account details. It will enable Learners to
            access content, and to have their performance tracked for analysis
            by teachers and parents.
          </li>
          <li>To communicate with you in response to enquiries</li>
          <li>
            To enhance any information that we receive from you with information
            obtained from third party data providers.
          </li>
          <li>
            To help improve the usefulness and effectiveness of our website and
            marketing channels
          </li>
          <li>
            To provide you with further information on how to set up an account
          </li>
          <li>
            Processing payments. All payments are processed by www.stripe.com.
          </li>
        </List>
      </Section>

      <Section>
        <SectionTitle>How We Store Your Personal Data</SectionTitle>
        <SectionSubtitle>Security</SectionSubtitle>
        <SectionDescription>
          We take appropriate measures to ensure that all personal data is kept
          secure including security measures to prevent personal data from being
          accidentally lost, or used or accessed in an unauthorised way. We
          limit access to your personal data to those who have a genuine
          business need to know it. Those processing your information will do so
          only in an authorised manner and are subject to a duty of
          confidentiality.
          <br />
          We also have procedures in place to deal with any suspected data
          security breach. We will notify you and any applicable regulator of a
          suspected data security breach where We are legally required to do so.
          <br />
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although We will do Our best to protect your
          personal data, We cannot guarantee the security of your data
          transmitted through any online means, therefore any transmission
          remains at your own risk.
          <br />
          <br />
        </SectionDescription>

        <SectionSubtitle>Where We store your personal data</SectionSubtitle>
        <SectionDescription>
          We store your personal data in our own systems and on the cloud,
          within the European Economic Area (“EEA”). <br /> If you would like
          further information please contact Us via contact@studyhall.ai. We
          will not otherwise transfer your personal data outside of the EEA or
          to any organisation (or subordinate bodies) governed by public
          international law or which is set up under any agreement between two
          or more countries.
          <br />
          <br />
        </SectionDescription>

        <SectionSubtitle>How long we keep your personal data</SectionSubtitle>
        <SectionDescription>
          We will hold the records for a period of 6 years in archive, in order
          to satisfy our legal obligations, including those related to the
          procurement of public contracts.
        </SectionDescription>
      </Section>

      <Section>
        <SectionTitle>Your rights</SectionTitle>
        <SectionDescription>
          Under the General Data Protection Regulation you have a number of
          important rights free of charge. In summary, those include rights to:
        </SectionDescription>
        <List>
          <li>
            access to your personal data and to certain other supplementary
            information that this Privacy Notice is already designed to address
          </li>
          <li>
            require Us to correct any mistakes in your information which We hold
          </li>
          <li>
            require the erasure of personal data concerning you in certain
            situations
          </li>
          <li>
            receive the personal data concerning you which you have provided to
            Us, in a structured, commonly used and machine-readable format
          </li>
          <li>
            request the removal of your personal data from the product and
            service upon cancellation of your contract with Study HallⓇ, Ltd.
          </li>
          <li>
            object at any time to processing of personal data concerning you for
            direct marketing
          </li>
          <li>
            object to decisions being taken by automated means which produce
            legal effects concerning you or similarly significantly affect you
          </li>
          <li>
            object in certain other situations to our continued processing of
            your personal data otherwise restrict our processing of your
            personal data in certain circumstances
          </li>
          <li>
            claim compensation for damages caused by our breach of any data
            protection laws.
          </li>
        </List>
      </Section>

      <Section>
        <SectionTitle>Further information</SectionTitle>
        <SectionDescription>
          If you have any questions, comments or complaints about this Notice or
          wish to: (i) exercise any of the rights described above; (ii) make any
          other type of request in relation to the processing of your Data; or
          (iii) report a concern or complaint related to the processing of your
          Data; you should contact:
        </SectionDescription>

        <SectionLink href="mailto:contact@studyhall.ai">
          contact@studyhall.ai
        </SectionLink>

        <SectionDescription>
          <br />
          We hope that we will be able to address any of your requests. However,
          you have the right to make a complaint about the processing of your
          personal data to the Information Commissioner's Office, Wycliffe
          House, Water Lane, Wilmslow, Cheshire, SK9 5AF.
          <br />
          <br />
        </SectionDescription>
      </Section>
    </Wrapper>
  );
};
