import styled from 'styled-components';

import media from '@/modules/media';

export const Wrapper = styled.div<{ mode?: 'normalView' | 'modalView' }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) =>
    props.mode === 'modalView' ? props.theme.space.md : 0};
`;

export const Title = styled.h2`
  font-size: ${(props) => props.theme.font.xl};
  color: ${(props) => props.theme.color.gray900};
  font-weight: 900;
  line-height: 32px;
  ${media.phone} {
    font-size: ${(props) => props.theme.font.lg};
  }
`;

export const Description = styled.p`
  font-size: ${(props) => props.theme.font.md};
  color: ${(props) => props.theme.color.gray3};
  margin-top: ${(props) => props.theme.space.md};
`;
