import media from '@/modules/media';
import { Modal, ModalContent, ModalFooter } from '@chakra-ui/react';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)``;
export const ModalWrapper = styled(ModalContent)`
  ${media.tabletLandscape} {
    header {
      display: none !important;
    }
  }
`;

export const NoPermissionModalText = styled.p`
  font-size: ${(props) => props.theme.font.xl};
  color: ${(props) => props.theme.color.gray900};
  margin-top: ${(props) => props.theme.space.xl};
  font-weight: 800;
  text-align: center;
`;

export const Footer = styled(ModalFooter)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${(props) => props.theme.space.lg};
  margin-bottom: ${(props) => props.theme.space.lg};
`;

export const NoPermissionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: ${(props) => props.theme.space.xxl};
  margin-bottom: ${(props) => props.theme.space.lg};
  width: 100%;
`;
