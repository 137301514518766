import { useMutation } from '@tanstack/react-query';

import { requestWithoutAccessToken } from '@/modules/apiRequests/userRequest';
import { showToast } from '@/modules/helpers';

type NewsletterSubData = {
  email: string;
};

const postSubscription = async (data: NewsletterSubData): Promise<unknown> => {
  const response = await requestWithoutAccessToken(
    'messaging/newsletter/subscribe',
    {
      email: data.email,
    },
    'POST'
  );
  if (response.errorMessage) {
    throw new Error(`Error! ${response.errorMessage}`);
  }
  return response;
};

export const useNewsletterSub = () => {
  return useMutation({
    mutationFn: (data) => postSubscription(data as NewsletterSubData),
    onSuccess: (data: any, variables: any) => {
      // showToast(
      //   'Changed!',
      //   'Your username has been changed successfully.',
      //   'success',
      //   2000
      // );
    },
    onError(error) {
      showToast('Error', `${error}`, 'error');
    },
  });
};
