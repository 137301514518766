import Avatar from '@/components/common/Avatar/Avatar';
import { UserType } from '@/types/common';

import Btn from '../Btn/Btn';
import {
  BoxActionWrapper,
  BoxAvatarDetailsWrapper,
  BoxUserCard as Card,
  BoxUserCardWrapper,
  Name,
  UserDetailsWrapper,
  UserName,
} from './BoxUserCard.styles';

type BoxCardProps = {
  user: UserType;
  action: () => void;
  buttonName: string;
};

const showRealName = (userItem: UserType): string => {
  if (userItem.givenName && userItem.surname) {
    return `${userItem.givenName} ${userItem.surname}`;
  }
  return `${userItem.username}`;
};

const BoxUserCard = ({
  user,
  action,
  buttonName,
}: BoxCardProps): JSX.Element => {
  return (
    <Card>
      <BoxUserCardWrapper>
        <BoxAvatarDetailsWrapper>
          <Avatar
            key={user.userId}
            profilePicture={user.profilePicture || ''}
            $status="gray50"
          />

          <UserDetailsWrapper>
            <Name>{showRealName(user)}</Name>
            <UserName>{user.username}</UserName>
          </UserDetailsWrapper>
        </BoxAvatarDetailsWrapper>

        <BoxActionWrapper>
          <Btn
            size="sm"
            w="auto"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              action();
            }}
          >
            {buttonName}
          </Btn>
        </BoxActionWrapper>
      </BoxUserCardWrapper>
    </Card>
  );
};
export default BoxUserCard;
