import 'react-image-crop/dist/ReactCrop.css';

import { useEffect, useMemo, useRef, useState } from 'react';
import { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';

import Loader from '@/common/Loader/Loader';
import ModalHeader from '@/common/ModalHeader/ModalHeader';
import Btn from '@/components/common/Btn/Btn';
import {
  BtnSave,
  ButtonCancel,
  ButtonSave,
  CropArea,
  ErrorMessage,
  PhotoDisplay,
  PhotoWrapper,
  Title,
  TitleTabletWrapper,
} from '@/components/learner/ImageCrop/ImageCrop.styles';
import { useUserProfileContext } from '@/contexts/UserProfileContext';
import { requestWithAccessToken } from '@/modules/apiRequests/userRequest';
import { ModalBodyContainer } from '@/styles/pagesStyles/learner/registration/addPhoto.styles';
import { useQueryClient } from '@tanstack/react-query';

type ImageCropProps = {
  imageFile: any;
  onResetImage: () => void;
  onHandleModal: () => void;
};

type HeaderType = {
  'Content-Type': string;
  'x-awz-acl': string;
};

const ImageCrop = ({
  imageFile,
  onResetImage,
  onHandleModal,
}: ImageCropProps) => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const { dispatch } = useUserProfileContext();
  const queryClient = useQueryClient();

  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>('');
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [croppedImage, setCroppedImage] = useState();

  const uploadFile = async (url: string, header: HeaderType) => {
    let response;

    try {
      const imageUploadResponse = await fetch(`${url}`, {
        method: 'PUT',
        body: croppedImage,
        headers: header,
      });

      if (imageUploadResponse.status === 200) {
        response = true;
      }
    } catch (error) {
      setUploadErrorMessage(
        'Something went wrong. Please try again. UPLOAD FILE'
      );
    }
    return response;
  };

  const uploadingStatus = useMemo(
    () =>
      uploadErrorMessage ? (
        <ErrorMessage>{uploadErrorMessage}</ErrorMessage>
      ) : (
        <Loader displayMessage="Uploading photo..." />
      ),
    [uploadErrorMessage]
  );

  const handleSaveClicked = async () => {
    setUploadingFile(true);

    try {
      const response = await requestWithAccessToken(
        `profile/profile-picture-upload-url?fileName=${imageFile[0]?.file?.name}`
      );

      if (response.errorMessage) {
        setUploadErrorMessage(response.errorMessage);
      } else if (response) {
        const { url, fileName, headers } = response;

        const isUploaded = await uploadFile(url, headers);

        if (isUploaded) {
          dispatch({
            type: 'UPDATE_PROFILE_PICTURE',
            payload: fileName || '',
          });
          queryClient.invalidateQueries(['profile']);
          onHandleModal();
          setUploadingFile(false);
          onResetImage();
        }
      }
    } catch (error) {
      setUploadErrorMessage(
        'Something went wrong. Please try again. HANDLE SAVE CLICKED 1ST'
      );
    }
  };

  const centerAspectCrop = (
    mediaWidth?: number,
    mediaHeight?: number,
    aspectRatio?: number
  ) => {
    const initialSizes =
      (mediaWidth || 1) / (mediaHeight || 1) < 1 ? 65 * (mediaWidth || 1) : 75;

    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: initialSizes,
          height: initialSizes,
        },
        aspectRatio || 1 / 1,
        mediaWidth || 0,
        mediaHeight || 0
      ),
      mediaWidth || 0,
      mediaHeight || 0
    );
  };

  useEffect(() => {
    setCrop(undefined); // Makes crop preview update between images.
    setCrop(
      centerAspectCrop(
        imgRef?.current?.naturalWidth,
        imgRef?.current?.naturalHeight,
        1 / 1
      )
    );
  }, []);

  const getCroppedImg = (image: HTMLImageElement, cropData: Crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropData.width;
    canvas.height = cropData.height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(
        image,
        cropData.x * scaleX,
        cropData.y * scaleY,
        cropData.width * scaleX,
        cropData.height * scaleY,
        0,
        0,
        cropData.width,
        cropData.height
      );

      canvas.toBlob((blob: any) => {
        if (blob) {
          /* eslint no-param-reassign: "error" */
          blob.name = imageFile[0]?.file?.name;
          blob.lastModified = imageFile[0]?.file?.lastModified;
          setCroppedImage(blob);
        }
      }, imageFile[0]?.file?.type);
    }
  };

  const updateCroppedImageFile = () => {
    if (imgRef.current) {
      getCroppedImg(imgRef.current, completedCrop as Crop);
    }
  };

  useEffect(() => {
    if (completedCrop) {
      updateCroppedImageFile();
    }
    if (
      Boolean(completedCrop) &&
      (completedCrop?.width === 0 || completedCrop?.height === 0)
    ) {
      setCrop(
        centerAspectCrop(
          imgRef?.current?.naturalWidth,
          imgRef?.current?.naturalHeight,
          1 / 1
        )
      );
    }
  }, [completedCrop]);

  return (
    <>
      <ModalHeader
        headerTitle="Crop your photo"
        onGoBackClicked={onResetImage}
        onCloseClicked={() => {
          onHandleModal();
          onResetImage();
        }}
      />
      <ModalBodyContainer>
        {Boolean(imageFile) && (
          <>
            <TitleTabletWrapper>
              <ButtonCancel
                onClick={() => {
                  onHandleModal();
                  onResetImage();
                }}
              >
                Cancel
              </ButtonCancel>
              <Title>Crop your photo</Title>
              <ButtonSave
                onClick={handleSaveClicked}
                isDisabled={uploadingFile}
              >
                Save
              </ButtonSave>
            </TitleTabletWrapper>
            <PhotoWrapper>
              {uploadingFile ? (
                uploadingStatus
              ) : (
                <CropArea
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                >
                  <PhotoDisplay
                    src={imageFile[0]?.data_url}
                    alt="Crop me"
                    ref={imgRef}
                  />
                </CropArea>
              )}
            </PhotoWrapper>
          </>
        )}
        <BtnSave>
          <Btn w={200} onClick={handleSaveClicked} isDisabled={uploadingFile}>
            Save
          </Btn>
        </BtnSave>
      </ModalBodyContainer>
    </>
  );
};

export default ImageCrop;
