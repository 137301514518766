import media from '@/modules/media';
import styled from 'styled-components';

export const BoxActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxAvatarDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const BoxUserCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 120px;
  width: 100%;
  box-shadow: 0px 0px 16px -1px rgba(208, 210, 216, 0.2);
`;

export const BoxUserCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${(props) => props.theme.space.xl};

  ${media.tablet} {
    flex-direction: column;
    gap: ${(props) => props.theme.space.md};
    align-items: flex-start;
  }
`;

export const Name = styled.p`
  font-size: ${(props) => props.theme.font.lg};
  font-weight: 700;
`;

export const UserDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => props.theme.space.md};
`;

export const UserName = styled.p`
  font-size: ${(props) => props.theme.font.md};
`;
