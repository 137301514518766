import styled from 'styled-components';

type CountryDropDownProps = {
  $isInvalid?: boolean;
};

export const Wrapper = styled.div`
  height: 100%;
  border-radius: 24px 0px 0px 24px;
  display: flex;
  align-items: center;
`;

export const CountryDropDown = styled.div<CountryDropDownProps>`
  width: 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: ${(props) =>
    props.$isInvalid
      ? `1px solid ${props.theme.color.red}`
      : `1px solid ${props.theme.color.gray25}`};
  gap: ${(props) => props.theme.space.md};

  background-color: ${(props) => props.theme.color.white};
  border-radius: 8px 0px 0px 8px;
`;

export const CountryFlag = styled.div`
  margin-top: -4px;
`;
export const SelectWrapper = styled.div`
  & > div {
    border: 1px solid ${(props) => props.theme.color.gray200};
    & > div:last-child {
      border: 1px solid ${(props) => props.theme.color.gray200};
      width: calc(100% + 2px);
      margin-left: -1px;
    }
  }
`;

export const CountryCode = styled.div`
  padding: 0 ${(props) => props.theme.font.xs};
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.font.md};
  color: ${(props) => props.theme.color.gray600};

  background-color: ${(props) => props.theme.color.white};
`;

export const OptionFlag = styled.div`
  font-size: ${(props) => props.theme.font.lg};
`;

export const OptionName = styled.div`
  font-size: ${(props) => props.theme.font.md};
`;

export const OptionCode = styled.div`
  font-size: ${(props) => props.theme.font.md};
`;
