import styled from 'styled-components';

import { LearnerProfileDto } from '@/store/user-profile/types';
import { ImageSizeType } from '@/types/common';

type PhotoDisplayWrapperType = {
  $status: LearnerProfileDto['mode'];
  $isEmoji?: boolean;
  $isGrayedOut?: boolean;
  size: ImageSizeType;
};

type PhotoWrapperType = {
  $isProfileModal?: boolean;
};

export const PhotoWrapper = styled.div<{ $hasMargin: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.$hasMargin && 'auto'};
`;

export const OuterRing = styled.div<PhotoDisplayWrapperType>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.theme.learnerStatusColors[`${props.$status}Outline`]};
  padding: ${(props) =>
    props.size === 'md'
      ? `${props.theme.space.md}`
      : `${props.theme.space.mdl}`};
  border-radius: ${(props) => (props.size === 'md' ? '38%' : '41%')};
  box-shadow: 0px 8px 24px 0px rgba(51, 56, 62, 0.2);
  width: ${(props) => (props.size === 'md' ? '168px' : '246px')};
  height: ${(props) => (props.size === 'md' ? '168px' : '246px')};
`;

export const PhotoDisplayWrapper = styled.div<PhotoDisplayWrapperType>`
  border-radius: ${(props) => (props.size === 'md' ? '38%' : '41%')};
  ${(props) =>
    props.$isEmoji &&
    `
    background-color: ${
      props.$isGrayedOut ? props.theme.color.gray25 : props.theme.color.yellow25
    };
  padding: ${props.theme.space.mdl};
   border: ${props.size === 'md' ? '8px' : '12px'} solid ${
      props.theme.learnerStatusColors[`${props.$status}Border`]
    };
     `};
  ${(props) =>
    !props.$isEmoji &&
    `
       background-color: ${
         props.theme.learnerStatusColors[`${props.$status}Border`]
       };
        padding: ${
          props.size === 'md'
            ? `${props.theme.space.md}`
            : `${props.theme.space.mdl}`
        };
          ${props.$isEmoji && `padding:  ${props.theme.space.xxl}`}
       `};

  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    transform: scale(1);
  }
`;

export const MainPhotoDisplay = styled.img.attrs({
  referrerPolicy: 'no-referrer',
})<{ $isGrayedOut?: boolean; size: ImageSizeType }>`
  border-radius: ${(props) => (props.size === 'md' ? '38%' : '41%')};
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  & > img {
    transform: scale(1);
  }

  ${(props) =>
    props.$isGrayedOut &&
    `
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
   -ms-filter: grayscale(100%);
       filter: grayscale(100%);
`};
`;

export const CameraWrapper = styled.div<PhotoWrapperType>`
  margin-left: ${(props) => (props.$isProfileModal ? `-12%;` : `-8%;`)};
  z-index: 1;
`;
