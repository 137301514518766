import Select, {
  OptionProps,
  StylesConfig,
  CSSObjectWithLabel,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';

import { colors } from '@/styles/colors';
import { custom } from '@/styles/custom';

type SelectProps = {
  hasValidationError: boolean;
  isDisabled?: boolean;
};

export const defaultStylesStudentTeacher: StylesConfig = {
  container: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    height: '40px',
    width: '100%',
    bg: `${colors.brand.white}`,

    '&:focus': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray200}`,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    gap: '10px',
    paddingRight: `${custom.space.lg}`,
  }),

  menu: (provided) => ({
    ...provided,
    outline: '1px solid white',
    boxShadow: '0px 0px 16px 0px rgba(208, 210, 216, 0.30)',
    border: '1px solid #D7DDE5',
    borderTop: 0,
    marginTop: 5,
    maxHeight: 150,
    overflow: 'hidden',
    position: 'absolute',
    top: '100%',
    marginLeft: '-40px',
    width: '109%',
    zIndex: 15,
  }),

  menuList: (provided) => ({
    ...provided,
    padding: 0,

    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: 8,
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    cursor: !state.isDisabled && 'pointer',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: state.isDisabled
      ? `${colors.brand.gray200}`
      : `${colors.brand.black}`,

    backgroundColor: state.isSelected && `${colors.brand.white}`,

    '&:hover': {
      background: !state.isDisabled && `${colors.brand.primary25}`,
      color: !state.isDisabled && `${colors.brand.black}`,
    },
  }),
};

export const defaultStylesMulti: StylesConfig = {
  container: (provided) => ({
    ...provided,
    border: `1px solid ${colors.brand.gray25}`,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    height: '56px',
    width: '100%',
    bg: `${colors.brand.white}`,

    '&:focus': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray200}`,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    gap: '10px',
    paddingRight: `${custom.space.lg}`,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontWeight: '400',
    color: state.isDisabled ? colors.brand.gray400 : colors.brand.gray500,
    fontFamily: 'GothamRounded',
  }),
  menu: (provided) => ({
    ...provided,
    outline: '1px solid white',
    boxShadow: '0px 0px 16px 0px rgba(208, 210, 216, 0.30)',
    borderRadius: '0px 0px 8px 8px',
    border: '1px solid #D7DDE5',
    borderTop: 0,
    marginTop: 0,
    top: '101%',
  }),

  menuList: (provided) => ({
    ...provided,
    padding: 0,

    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  control: () => ({
    display: 'flex',
    border: '1px solid #D7DDE5',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    width: '100%',
    borderRadius: '8px 8px 0 0',
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    cursor: !state.isDisabled && 'pointer',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: state.isDisabled
      ? `${colors.brand.gray200}`
      : `${colors.brand.black}`,

    backgroundColor: state.isSelected && `${colors.brand.white}`,

    '&:hover': {
      background: !state.isDisabled && `${colors.brand.primary25}`,
      color: !state.isDisabled && `${colors.brand.black}`,
    },
  }),
  group: (provided: any, state) => ({
    ...provided,
    '&:not:first-child': {
      borderTop: `1px solid ${colors.brand.gray200}`,
    },
  }),
};

export const narrowStyles: StylesConfig = {
  container: (provided, state) => ({
    ...provided,
    border: `1px solid  ${
      state.isDisabled ? colors.brand.gray25 : colors.brand.gray300
    }`,
    opacity: '1 !important',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    height: '40px',
    width: '100%',
    background: `${colors.brand.white}`,

    '&:focus': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray200}`,
    },
    pointerEvents: 'all',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: 'flex',
    gap: '10px',
    paddingRight: `${custom.space.lg}`,
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: '16px',
    zIndex: 1000,
    outline: '1px solid white',
    boxShadow: '0px 8px 24px 0px rgba(51, 56, 62, 0.2)',
  }),

  menuList: (provided) => ({
    ...provided,

    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #D7DDE5',
    width: '100%',
    minHeight: '40px',
    borderRadius: '8px',
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: state.isDisabled
      ? `${colors.brand.gray200}`
      : `${colors.brand.black}`,
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,

    backgroundColor: state.isSelected && `${colors.brand.white}`,
    cursor: !state.isDisabled && 'pointer',
    '&:hover': {
      background: !state.isDisabled && `${colors.brand.primary25}`,
      color: !state.isDisabled && `${colors.brand.black}`,
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontWeight: '400',
    color: state.isDisabled ? colors.brand.gray400 : colors.brand.gray500,
    fontFamily: 'GothamRounded',
  }),
  group: (provided: any, state) => ({
    ...provided,
    padding: `${custom.space.md} 0`,
    '&:not(:first-child)': {
      '&::before': {
        content: '""',
        height: '1px',
        display: 'block',
        margin: `${custom.space.sm} ${custom.space.lg}`,
        background: colors.brand.gray200,
        position: 'relative',
        top: '-6px',
      },
    },
  }),
  groupHeading: (provided: any, state) => ({
    ...provided,
    // styleName: Paragraph xs/Bold;
    fontSize: '12px',
    fontWeight: 800,
    color: colors.brand.gray600,
    textTransform: 'capitalize',
    padding: `${custom.space.sm} ${custom.space.lg}`,
  }),
};

export const defaultStyles: StylesConfig = {
  container: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    border: `1px solid ${colors.brand.gray25}`,
    display: 'flex',
    justifyContent: 'space-between',
    height: !state.isMulti && '56px',
    borderRadius: '8px',
    width: '100%',
    bg: `${colors.brand.white}`,
    cursor: 'pointer',

    '&:focus': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray200}`,
    },
    '&:focus-within': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 0px ${colors.brand.gray200}`,
    },
  }),
  input: (provided) => ({
    ...provided,
    position: 'absolute',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    gap: '10px',
    paddingRight: `${custom.space.lg}`,
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: '16px',
    outline: '1px solid white',
    boxShadow: '0px 8px 24px 0px rgba(51, 56, 62, 0.2)',
  }),

  menuList: (provided) => ({
    ...provided,

    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #CDD5DF',
    width: '100%',
    padding: '4px 8px',
    borderRadius: '8px',
  }),

  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    gap: '8px',
  }),

  multiValue: () => ({
    display: 'flex',
    border: '1px solid #CDD5DF',
    padding: '4px 8px',
    borderRadius: '8px',
  }),
  singleValue: () => ({
    whiteSpace: 'normal',
  }),
  placeholder: (provided) => ({
    fontWeight: '400',
    color: '#90959C',
    fontFamily: 'GothamRounded',
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    margin: 'auto',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    cursor: !state.isDisabled && 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: state.isDisabled
      ? `${colors.brand.gray200}`
      : `${colors.brand.black}`,
    padding: `${custom.space.md} ${custom.space.lg}`,
    backgroundColor: state.isSelected && `${colors.brand.white}`,
    '&:hover': {
      background: !state.isDisabled && `${colors.brand.primary25}`,
      color: !state.isDisabled && `${colors.brand.black}`,
    },
  }),
  group: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: `${custom.space.md} 0`,
    '&:not(:first-of-type)': {
      '&::before': {
        content: '""',
        height: '1px',
        display: 'block',
        margin: `${custom.space.sm} ${custom.space.lg}`,
        background: colors.brand.gray200,
        position: 'relative',
        top: '-6px',
      },
    },
  }),
  groupHeading: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: '12px',
    fontWeight: 800,
    color: colors.brand.gray600,
    textTransform: 'capitalize',
    padding: `${custom.space.sm} ${custom.space.lg}`,
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({
    ...provided,
    zIndex: '200',
  }),
};

export const flashCardTypesStyles: StylesConfig = {
  ...defaultStyles,
  container: (provided: any) => ({
    ...provided,
    border: 'none',
    display: 'flex',
    background: 'transparent',

    '&:focus': {
      border: 'none',
    },
    '&:focus-within': {
      border: 'none',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: 'none',
    width: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
    borderRadius: '16px',
    backgroundColor: 'white',
    width: '260px',
    boxShadow: '0px 0px 16px 0px rgba(208, 210, 216, 0.30)',
  }),
  singleValue: (provided, state: any) => {
    return {
      ...provided,
      color: state.data.color,
      fontWeight: 900,
      whiteSpace: 'normal',
      fontSize: '20px',
    };
  },
};

export const notificationStyles: StylesConfig = {
  ...defaultStyles,
  container: (provided: any, state) => ({
    ...provided,
    ...provided,
    border: `1px solid ${colors.brand.gray25}`,
    display: 'flex',
    justifyContent: 'space-between',
    height: !state.isMulti && '56px',
    borderRadius: '8px',
    width: '100%',
    maxWidth: '300px',
    marginTop: '24px',
    maxHeight: '40px',
    bg: `${colors.brand.white}`,
    cursor: 'pointer',

    '&:focus': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray200}`,
    },
    '&:focus-within': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 0px ${colors.brand.gray200}`,
    },
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '140px',

    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
};

export const theScienceOfLearningStyles: StylesConfig = {
  container: (provided: any, state) => ({
    ...provided,
    border: `1px solid ${colors.brand.gray25}`,
    display: 'flex',
    justifyContent: 'space-between',
    height: !state.isMulti && '56px',
    borderRadius: '8px',
    width: '100%',
    bg: `${colors.brand.white}`,
    cursor: 'pointer',

    '&:focus': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray200}`,
    },
    '&:focus-within': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 0px ${colors.brand.gray200}`,
    },
  }),
  input: (provided) => ({
    ...provided,
    position: 'absolute',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    gap: '10px',
    paddingRight: `${custom.space.lg}`,
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: '16px',
    outline: '1px solid white',
    boxShadow: '0px 8px 24px 0px rgba(51, 56, 62, 0.2)',
  }),

  menuList: (provided) => ({
    ...provided,

    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #CDD5DF',
    width: '100%',
    borderRadius: '8px',
  }),

  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    gap: '8px',
  }),

  multiValue: () => ({
    display: 'flex',
    border: '1px solid #CDD5DF',
    padding: '4px 8px',
    borderRadius: '8px',
  }),
  singleValue: () => ({
    whiteSpace: 'normal',
  }),
  placeholder: (provided) => ({
    fontWeight: '400',
    color: '#90959C',
    fontFamily: 'GothamRounded',
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    cursor: !state.isDisabled && 'pointer',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: state.isDisabled
      ? `${colors.brand.gray200}`
      : `${colors.brand.black}`,

    backgroundColor: state.isSelected && `${colors.brand.white}`,

    '&:hover': {
      background: !state.isDisabled && `${colors.brand.primary25}`,
      color: !state.isDisabled && `${colors.brand.black}`,
    },
  }),
};

export const teacherAssessmentStyles: StylesConfig = {
  container: (provided: any, state) => ({
    ...provided,
    border: `1px solid ${colors.brand.gray25}`,
    display: 'flex',
    justifyContent: 'space-between',
    height: !state.isMulti && '56px',
    borderRadius: '8px',
    width: '100%',
    bg: `${colors.brand.white}`,
    cursor: 'pointer',

    '&:focus': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray200}`,
    },
    '&:focus-within': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 0px ${colors.brand.gray200}`,
    },
  }),
  input: (provided) => ({
    ...provided,
    position: 'absolute',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    gap: '10px',
    paddingRight: `${custom.space.lg}`,
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: '16px',
    outline: '1px solid white',
    boxShadow: '0px 8px 24px 0px rgba(51, 56, 62, 0.2)',
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: '200px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #CDD5DF',
    width: '100%',
    borderRadius: '8px',
  }),

  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    gap: '8px',
  }),

  multiValue: () => ({
    display: 'flex',
    border: '1px solid #CDD5DF',
    padding: '4px 8px',
    borderRadius: '8px',
  }),
  singleValue: () => ({
    whiteSpace: 'normal',
  }),
  placeholder: (provided) => ({
    fontWeight: '400',
    color: '#90959C',
    fontFamily: 'GothamRounded',
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    cursor: !state.isDisabled && 'pointer',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: state.isDisabled
      ? `${colors.brand.gray200}`
      : `${colors.brand.black}`,

    backgroundColor: state.isSelected && `${colors.brand.white}`,

    '&:hover': {
      background: !state.isDisabled && `${colors.brand.primary25}`,
      color: !state.isDisabled && `${colors.brand.black}`,
    },
  }),
};

export const countryStyles: StylesConfig = {
  container: (provided) => ({
    ...provided,
    border: `1px solid ${colors.brand.white}`,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px 8px 0px 0px',
    height: '51px',
    width: '320px',
    position: 'absolute',
    top: '63px',
    left: '-2px',
    background: `${colors.brand.white}`,
    zIndex: 1,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    width: '100%',
    height: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    height: '245px',
    borderRadius: '0 0 8px 8px',
    margin: '-2px 0px 0px 0px',
    boxShadow: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    height: '245px',
    borderRadius: '0 0 24px 24px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0)',

    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    width: '100%',

    borderRadius: '24px',
    paddingLeft: `${custom.space.lg}`,
  }),
  option: (provided, state) => ({
    ...provided,
    height: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    cursor: !state.isDisabled ? 'pointer' : 'default',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: `${custom.space.md}`,
    color: `${colors.brand.black}`,

    backgroundColor: state.isFocused
      ? `${colors.brand.primary25}`
      : 'transparent',

    '&:focus': {
      background: `${colors.brand.primary25}`,
      color: `${colors.brand.black}`,
    },

    '&:hover': {
      background: `${colors.brand.primary25}`,
      color: `${colors.brand.black}`,
    },
    '& >div:first-child': {
      marginTop: '-5px',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(0,0,0,0.4)',
    margin: 0,
    padding: 0,
    marginTop: 0,
  }),
};

export const countryStylesError: StylesConfig = {
  ...countryStyles,
  container: (provided) => ({
    ...provided,
    borderRight: 'none',
    display: 'flex',
    borderRadius: '24px 0 0 24px',
    height: '48px',
    width: '65px',
  }),
};

export const examsStyles: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    border: `1px solid ${isDisabled ? colors.brand.gray300 : 'white'}`,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    minHeight: '40px',
    width: '100%',
    background: isDisabled ? 'none' : `${colors.brand.white}`,

    '&:focus-within': {
      border: `1px solid ${colors.brand.gray300}`,
      boxShadow: `0px 0px 0px 0px ${colors.brand.gray300}`,
    },
  }),
};

export const raceExamsStyles: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    minHeight: '40px',
    width: '100%',
    background: isDisabled ? 'none' : `${colors.brand.white}`,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 4,
    overflow: 'hidden',
    paddingRight: '4px',
    borderRadius: '16px',
    boxShadow: '0px 0px 16px 0px #D0D2D84D',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '240px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  option: (provided: any, state) => ({
    ...provided,
    height: '56px',
    cursor: 'pointer',
    background: 'transparent',
    paddingLeft: '16px',

    '&:hover': {
      background: !state.isDisabled && `${colors.brand.primary25}`,
      color: !state.isDisabled && `${colors.brand.black}`,
    },
  }),
};
export const mockExamsStyles: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    minHeight: '40px',
    width: '100%',
    background: isDisabled ? 'none' : `${colors.brand.white}`,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 4,
    overflow: 'hidden',
    paddingRight: '4px',
    borderRadius: '16px',
    boxShadow: '0px 0px 16px 0px #D0D2D84D',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '200px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  option: (provided: any, state) => ({
    ...provided,
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'transparent',
    paddingLeft: '16px',
    '&:hover': {
      background: !state.isDisabled && `${colors.brand.primary25}`,
      color: !state.isDisabled && `${colors.brand.black}`,
    },
  }),
};

export const diagnosticGroupStyles: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    minHeight: '40px',
    width: '100%',
    maxWidth: '198px !important',
    minWidth: '198px !important',
    background: isDisabled ? 'none' : `${colors.brand.white}`,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 4,
    overflow: 'hidden',
    paddingRight: '4px',
    borderRadius: '16px',
    boxShadow: '0px 0px 16px 0px #D0D2D84D',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '240px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  option: (provided: any) => ({
    ...provided,
    height: 'auto',
    cursor: 'pointer',
    background: 'transparent',
    paddingLeft: '16px',

    '&:hover': {
      background: `${colors.brand.gray25}`,
    },
  }),
};
export const adminDashboardStyles: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    minHeight: '40px',
    width: '100%',
    maxWidth: '214px !important',
    background: isDisabled ? 'none' : `${colors.brand.white}`,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 4,
    overflow: 'hidden',
    paddingRight: '4px',
    borderRadius: '16px',
    boxShadow: '0px 0px 16px 0px #D0D2D84D',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '240px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  option: (provided: any) => ({
    ...provided,
    height: 'auto',
    cursor: 'pointer',
    background: 'transparent',
    paddingLeft: '16px',

    '&:hover': {
      background: `${colors.brand.gray25}`,
    },
  }),
};
export const adminStudentStyles: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    minHeight: '40px',
    width: '100%',
    maxWidth: '282px !important',
    background: isDisabled ? 'none' : `${colors.brand.white}`,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 4,
    overflow: 'hidden',
    paddingRight: '4px',
    borderRadius: '16px',
    boxShadow: '0px 0px 16px 0px #D0D2D84D',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '240px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  option: (provided: any, state: OptionProps) => ({
    ...provided,
    background: 'transparent',
    paddingLeft: '16px',
    height: '40px',
    fontSize: '14px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    cursor: !state.isDisabled ? 'pointer' : 'default',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: `${custom.space.md}`,
    color: `${colors.brand.black}`,

    backgroundColor: state.isFocused
      ? `${colors.brand.primary25}`
      : 'transparent',

    '&:focus': {
      background: `${colors.brand.primary25}`,
      color: `${colors.brand.black}`,
    },

    '&:hover': {
      background: `${colors.brand.primary25}`,
      color: `${colors.brand.black}`,
    },
    '& >div:first-child': {
      marginTop: '-5px',
    },
  }),
};
export const adminStudentDashboardStyles: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    minHeight: '40px',
    width: '100%',
    maxWidth: '542px !important',
    background: isDisabled ? 'none' : `${colors.brand.white}`,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 4,
    overflow: 'hidden',
    paddingRight: '4px',
    borderRadius: '16px',
    boxShadow: '0px 0px 16px 0px #D0D2D84D',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '240px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  option: (provided: any, state: OptionProps) => {
    return {
      ...provided,
      minHeight: '40px',
      height: 'auto',
      fontSize: '14px',
      lineHeight: '14px',
      fontWeight: 600,
      padding: `${custom.space.md} ${custom.space.lg}`,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      backgroundColor:
        state.isFocused || state.isSelected
          ? `${colors.brand.primary25}`
          : 'transparent',
      '&:focus': {
        background: `${colors.brand.primary25}`,
        color: `${colors.brand.black}`,
      },
      '&:hover': {
        background: `${colors.brand.primary25}`,
        color: `${colors.brand.black}`,
      },
    };
  },
};
export const singleStudentDashboardStyles: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    minHeight: '40px',
    width: '100%',
    maxWidth: '100% !important',
    background: isDisabled ? 'none' : `${colors.brand.white}`,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 4,
    overflow: 'hidden',
    paddingRight: '4px',
    borderRadius: '16px',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '240px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  option: (provided: any, state: OptionProps) => ({
    ...provided,
    cursor: 'pointer',
    paddingLeft: '16px',
    height: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: `${custom.space.md}`,
    color: `${colors.brand.black}`,

    backgroundColor: state.isFocused
      ? `${colors.brand.primary25}`
      : 'transparent',

    '&:focus': {
      background: `${colors.brand.primary25}`,
      color: `${colors.brand.black}`,
    },

    '&:hover': {
      background: `${colors.brand.primary25}`,
      color: `${colors.brand.black}`,
    },
  }),
};
export const AsynchronousSelect = styled(AsyncSelect)<SelectProps>`
  border-color: ${(props) =>
    props.hasValidationError && `${colors.brand.red}`}!important;
  border-width: ${(props) => props.hasValidationError && `1px`}!important;
  box-shadow: ${(props) => props.hasValidationError && `none`}!important;
  background: ${colors.brand.white};
  max-width: 100% !important;
  transition: all 0.08s ease-in-out !important;
`;

export const DefaultSelect = styled(Select)<SelectProps>`
  border-color: ${(props) =>
    props.hasValidationError && `${colors.brand.red}`}!important;
  border-width: ${(props) => props.hasValidationError && `1px`}!important;
  box-shadow: ${(props) => props.hasValidationError && `none`}!important;
  background: ${colors.brand.white};
  max-width: 100% !important;
  transition: all 0.08s ease-in-out !important;

  cursor: ${(props) =>
    props.isDisabled ? 'not-allowed' : 'pointer'} !important;
  opacity: ${(props) => (props.isDisabled ? '0.4' : '1')} !important;
  max-height: 150px;
`;

export const countryDropdown: StylesConfig = {
  container: (provided) => ({
    ...provided,
    border: `1px solid ${colors.brand.gray300}`,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    height: '48px',
    width: '100%',
    minWidth: '545px',
    bg: `${colors.brand.white}`,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    gap: '10px',
    paddingRight: `${custom.space.lg}`,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '16px',
  }),
  menuList: (provided) => ({
    ...provided,

    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '24px',
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    cursor: !state.isDisabled && 'pointer',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: `${colors.brand.black}`,

    backgroundColor: state.isSelected && `${colors.brand.white}`,

    '&:hover': {
      background: `${colors.brand.primary25}`,
      color: `${colors.brand.black}`,
    },
  }),
};

export const countryDropdownSmall: StylesConfig = {
  container: (provided) => ({
    ...provided,
    border: `1px solid ${colors.brand.gray300}`,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    height: '48px',
    width: '100%',
    minWidth: '345px',
    bg: `${colors.brand.white}`,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    gap: '10px',
    paddingRight: `${custom.space.lg}`,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '16px',
  }),
  menuList: (provided) => ({
    ...provided,

    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '24px',
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    cursor: !state.isDisabled && 'pointer',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: `${colors.brand.black}`,

    backgroundColor: state.isSelected && `${colors.brand.white}`,

    '&:hover': {
      background: `${colors.brand.primary25}`,
      color: `${colors.brand.black}`,
    },
  }),
};

export const teacherAssignmentsSelect: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    maxHeight: '40px',
    height: '100%',
    maxWidth: '222px',
    width: '100%',
    background: isDisabled ? 'none' : `${colors.brand.white}`,
    border: '1px solid transparent',
    cursor: 'pointer',
    transition: 'all 0.08s ease-in-out',

    '&:focus-within': {
      border: `1px solid ${colors.brand.gray300}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray300}`,
    },
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: '8px',
    border: 'none',
    boxShadow: 'none',
    minHeight: '37px',
    cursor: 'pointer',
  }),
};

export const teacherSubjectsSelect: StylesConfig = {
  ...defaultStyles,
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
    borderRadius: '16px',
    outline: 'none',
    boxShadow: '0px 8px 24px 0px rgba(51, 56, 62, 0.2)',
  }),

  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    maxHeight: '40px',
    height: '100%',
    maxWidth: '33%',
    width: '100%',
    background: isDisabled ? 'none' : `${colors.brand.white}`,
    border: '1px solid transparent',
    cursor: 'pointer',
    transition: 'all 0.08s ease-in-out',

    '&:focus-within': {
      border: `1px solid ${colors.brand.gray300}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray300}`,
    },
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: '8px',
    border: 'none',
    boxShadow: 'none',
    minHeight: '37px',
    cursor: 'pointer',
  }),
  option: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    cursor: !state.isDisabled && 'pointer',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: state.isDisabled
      ? `${colors.brand.gray200}`
      : `${colors.brand.black}`,

    backgroundColor: state.isSelected && `${colors.brand.white}`,

    '&:hover': {
      background: !state.isDisabled && `${colors.brand.primary25}`,
      color: !state.isDisabled && `${colors.brand.black}`,
    },
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '256px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
};

export const resourcesSelect: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    maxHeight: '43px',
    height: '100%',
    maxWidth: '344px',
    width: '100%',
    border: '1px solid transparent',
    cursor: 'pointer',
    transition: 'all 0.08s ease-in-out',

    '&:focus-within': {
      border: `1px solid ${colors.brand.gray300}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray300}`,
    },

    '@media (max-width: 768px)': {
      maxWidth: '100%',
    },
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: '8px',
    border: 'none',
    boxShadow: 'none',
    minHeight: '40px',
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '40px',
    maxHeight: '40px',
  }),
  singleValue: (provided) => ({
    ...provided,
    maxWidth: '290px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '@media (max-width: 768px)': {
      maxWidth: '100%',
    },
  }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
    borderRadius: '16px',
    outline: 'none',
    boxShadow: '0px 8px 24px 0px rgba(51, 56, 62, 0.2)',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '256px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
};

export const resourcesCustomerSelect: StylesConfig = {
  ...defaultStyles,
  container: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    maxHeight: '59px',
    height: '100%',
    maxWidth: '344px',
    width: '100%',
    border: '1px solid transparent',
    cursor: 'pointer',
    transition: 'all 0.08s ease-in-out',

    '&:focus-within': {
      border: `1px solid ${colors.brand.gray300}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray300}`,
    },

    '@media (max-width: 768px)': {
      maxWidth: '100%',
    },
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: '8px',
    border: 'none',
    boxShadow: 'none',
    minHeight: '56px',
    cursor: 'pointer',
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '56px',
    maxHeight: '56px',
  }),
  singleValue: (provided) => ({
    ...provided,
    maxWidth: '290px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '@media (max-width: 768px)': {
      maxWidth: '100%',
    },
  }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
    borderRadius: '16px',
    outline: 'none',
    boxShadow: '0px 8px 24px 0px rgba(51, 56, 62, 0.2)',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '256px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
};

export const studyGroupsSelect: StylesConfig = {
  container: (provided: any, state) => ({
    ...provided,
    border: `1px solid ${colors.brand.gray25}`,
    display: 'flex',
    justifyContent: 'space-between',
    height: !state.isMulti && '56px',
    borderRadius: '8px',
    width: '100%',
    bg: `${colors.brand.white}`,
    cursor: 'pointer',

    '&:focus': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 1px ${colors.brand.gray200}`,
    },
    '&:focus-within': {
      border: `1px solid ${colors.brand.gray200}`,
      boxShadow: `0px 0px 0px 0px ${colors.brand.gray200}`,
    },
  }),
  input: (provided) => ({
    ...provided,
    position: 'absolute',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    gap: '10px',
    paddingRight: `${custom.space.lg}`,
  }),
  menu: (provided) => ({
    ...provided,
    overflow: 'hidden',
    borderRadius: '16px',
    outline: 'none',
    boxShadow: '0px 8px 24px 0px rgba(51, 56, 62, 0.2)',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '190px',
    '::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: `${colors.brand.gray300}`,
      height: '8px',
      borderRadius: '50px',
    },

    '::-webkit-scrollbar-track-piece:end': {
      backgroundColor: `${colors.brand.gray50}`,
      marginLeft: '12px',
      borderRadius: '50px',
      marginBottom: '8px',
    },

    '::-webkit-scrollbar-track-piece:start': {
      backgroundColor: `${colors.brand.gray50}`,
      marginRight: '12px',
      borderRadius: '50px',
      marginTop: '8px',
    },
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #CDD5DF',
    width: '100%',
    minHeight: '40px',
    borderRadius: '8px',
  }),

  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    gap: '8px',
  }),

  multiValue: () => ({
    display: 'flex',
    border: '1px solid #CDD5DF',
    padding: '4px 8px',
    borderRadius: '8px',
  }),
  singleValue: () => ({
    whiteSpace: 'normal',
  }),
  placeholder: (provided) => ({
    fontWeight: '400',
    color: '#90959C',
    fontFamily: 'GothamRounded',
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: (provided: any, state) => ({
    ...provided,
    minHeight: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: `${custom.space.md} ${custom.space.lg}`,
    cursor: !state.isDisabled && 'pointer',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: state.isDisabled
      ? `${colors.brand.gray200}`
      : `${colors.brand.black}`,

    backgroundColor: state.isSelected && `${colors.brand.white}`,

    '&:hover': {
      background: !state.isDisabled && `${colors.brand.primary25}`,
      color: !state.isDisabled && `${colors.brand.black}`,
    },
  }),
};

type Size = 'sm' | 'md';
type Variant = 'gray' | 'white';

export type DefaultStylesOptions = {
  size: Size;
  variant: Variant;
};

export const DefaultSelectStyles = ({
  size,
  variant,
}: DefaultStylesOptions): StylesConfig => {
  const heightMap = {
    sm: '40px',
    md: '56px',
  };

  const variantMap = {
    gray: '0px',
    white: '1px',
  };

  const disabledColorMap = {
    gray: colors.brand.gray25,
    white: colors.brand.white,
  };

  return {
    container: (provided, { isDisabled, isFocused }) => ({
      ...provided,
      boxSizing: 'border-box',
      border: `${isFocused || isDisabled ? '1px' : variantMap[variant]} solid ${
        colors.brand.gray300
      }`,
      boxShadow: `${
        isFocused || isDisabled
          ? `0px 0px 0px 1px ${colors.brand.gray300}`
          : 'none'
      }`,
      display: 'flex',
      justifyContent: 'space-between',

      borderRadius: '8px',
      height: heightMap[size],
      width: '100%',
      backgroundColor: `${
        isDisabled ? disabledColorMap[variant] : colors.brand.white
      }`,

      color: '#90959C',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px' /* 150% */,
    }),

    control: () => ({
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      width: '0px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'flex',
      gap: '10px',
      paddingRight: `${custom.space.lg}`,
    }),

    menu: (provided) => ({
      ...provided,
      borderRadius: '16px',
      outline: '1px solid white',
      boxShadow: '0px 8px 24px 0px rgba(51, 56, 62, 0.2)',
    }),

    menuList: (provided) => ({
      ...provided,
      '::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        display: 'block',
        width: '8px',
        height: '8px',
        borderRadius: '50px',
      },

      '::-webkit-scrollbar-thumb': {
        backgroundColor: `${colors.brand.gray300}`,
        height: '8px',
        borderRadius: '50px',
      },

      '::-webkit-scrollbar-track-piece:end': {
        backgroundColor: `${colors.brand.gray50}`,
        borderRadius: '50px',
        marginBottom: '8px',
      },

      '::-webkit-scrollbar-track-piece:start': {
        backgroundColor: `${colors.brand.gray50}`,
        borderRadius: '50px',
        marginTop: '8px',
      },
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (provided: any, state) => ({
      ...provided,
      height: '40px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      padding: `${custom.space.md} ${custom.space.lg}`,
      cursor: !state.isDisabled && 'pointer',
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      color: state.isDisabled
        ? `${colors.brand.gray200}`
        : `${colors.brand.black}`,

      backgroundColor: state.isSelected && `${colors.brand.white}`,

      '&:hover': {
        background: !state.isDisabled && `${colors.brand.primary25}`,
        color: !state.isDisabled && `${colors.brand.black}`,
      },
    }),
  };
};
