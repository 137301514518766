import Image from 'next/image';

import { icons } from '@/components/common/Icon/types';
import { loadImgFromCdn } from '@/modules/imageLoader';

import { Wrapper } from './Logo.styles';

const type = 'logoFull';

type LogoType = {
  onClick?: () => void;
};

const Logo = ({ onClick }: LogoType): JSX.Element => (
  <Wrapper>
    <Image
      src={icons[type]}
      loader={() => loadImgFromCdn(icons[type])}
      placeholder="blur"
      width={191}
      height={40}
      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8UA8AAmUBcaVexNkAAAAASUVORK5CYII="
      color="black"
      alt={type}
      style={{
        cursor: onClick && 'pointer',
      }}
      priority
      onClick={onClick}
    />
  </Wrapper>
);

export default Logo;
