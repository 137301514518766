import {
  Hide,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Show,
  useDisclosure,
} from '@chakra-ui/react';
import { useNewsletterSub } from 'hooks/useNewsletterSub';
import Link from 'next/link';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { isValidEmail } from '@/modules/helpers';

import Btn from '../Btn/Btn';
import Icon from '../Icon/Icon';
import ModalHeader from '../ModalHeader/ModalHeader';
import { Privacy, Terms } from '../Terms&Policy/Terms&Policy';
import {
  ColumnWrapper,
  CopyRights,
  CopyrightSmall,
  FAQs,
  FooterDivider,
  FooterWrapper,
  IconWrapper,
  InfoHeader,
  MobileFooterDivider,
  Newsletter,
  NewsLetterActions,
  NewsletterForm,
  NewsletterHeader,
  NewsletterInput,
  NewsLetterThankyouWrapper,
  SocialLink,
  Socials,
  StudyHallInfo,
  Wrapper,
} from './Footer.styles';

const socials = [
  {
    ariaLabel: 'linkedIn',
    icon: <Icon type="linkedIn" size="status-sm" />,
  },
];

type ModalType = 'terms' | 'privacy';
type InitialNewsletterValuesType = {
  email: string;
};

const modalSettings = {
  terms: {
    title: 'Terms of Use',
    content: <Terms />,
  },
  privacy: {
    title: 'Privacy Policy',
    content: <Privacy />,
  },
};

const Footer = (): JSX.Element => {
  const [modalType, setModalType] = useState<ModalType>('terms' as ModalType);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addShowForm, setAddShowForm] = useState(true);
  const newsletterSub = useNewsletterSub();

  const handleModal = (type: ModalType) => {
    setModalType(type);
    onOpen();
  };

  const { handleSubmit, register, reset } =
    useForm<InitialNewsletterValuesType>({
      mode: 'onSubmit',
      delayError: 0,
      defaultValues: {},
    });

  const onSubmit: SubmitHandler<InitialNewsletterValuesType> = async (
    data: InitialNewsletterValuesType
  ) => {
    newsletterSub.mutate(data, {
      onSuccess: () => {
        reset();
        setAddShowForm(false);
      },
      onError: () => {
        reset();
      },
    });
  };

  return (
    <Wrapper>
      <Modal isOpen={isOpen} onClose={onClose} isCentered variant="termsModal">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            headerTitle={modalSettings[modalType].title}
            onCloseClicked={onClose}
          />

          <ModalBody>{modalSettings[modalType].content}</ModalBody>
        </ModalContent>
      </Modal>

      <FooterWrapper>
        <Newsletter>
          <IconWrapper>
            <Icon type="status" size="lg" color="white" />

            <Show breakpoint="(max-width: 1024px)">
              <Socials>
                {socials.map((social) => (
                  <SocialLink
                    href={social.ariaLabel}
                    key={social.ariaLabel}
                    aria-label={social.ariaLabel}
                  >
                    {social.icon}
                  </SocialLink>
                ))}
              </Socials>
            </Show>
          </IconWrapper>

          <Show breakpoint="(max-width: 1024px)">
            <MobileFooterDivider />
          </Show>

          <NewsLetterActions>
            {addShowForm ? (
              <NewsletterForm
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <NewsletterHeader>Subscribe to our newsletter</NewsletterHeader>

                <NewsletterInput
                  placeholder="Enter your email"
                  type="email"
                  id="email"
                  size="lg"
                  {...register('email', {
                    required: 'Email is required',
                    validate: {
                      isValidEmail,
                    },
                  })}
                />

                <Hide breakpoint="(max-width: 490px)">
                  <Btn
                    variant="secondary"
                    w={112}
                    type="submit"
                    isLoading={newsletterSub.isLoading}
                  >
                    Subscribe
                  </Btn>
                </Hide>

                <Show breakpoint="(max-width: 490px)">
                  <Btn
                    variant="secondary"
                    w="default"
                    type="submit"
                    isLoading={newsletterSub.isLoading}
                  >
                    Subscribe
                  </Btn>
                </Show>
              </NewsletterForm>
            ) : (
              <NewsLetterThankyouWrapper>
                The subscription was successful. Thank you!
              </NewsLetterThankyouWrapper>
            )}
          </NewsLetterActions>
        </Newsletter>

        <Hide breakpoint="(max-width: 1024px)">
          <FooterDivider />
        </Hide>

        <StudyHallInfo>
          <FAQs>
            <ColumnWrapper>
              <InfoHeader>Schools</InfoHeader>

              <Link href="/">Bursary Program</Link>
              <Link href="/">Early Sign Up</Link>
              <Link href="/">Book Drive</Link>
            </ColumnWrapper>

            <ColumnWrapper>
              <InfoHeader>Guides</InfoHeader>
              <Link href="/">Evidence-based 11+/13+ Guide</Link>
              <Link href="/">Evidence-based SATs Guide</Link>
            </ColumnWrapper>

            <ColumnWrapper>
              <InfoHeader>About us</InfoHeader>
              <Link href="/ourMission">Our Mission</Link>
              <Link href="/">Our Team</Link>
            </ColumnWrapper>

            <ColumnWrapper>
              <InfoHeader>Help</InfoHeader>
              <Link href="/faq">FAQs</Link>
              <Link href="/">Contact Us</Link>
            </ColumnWrapper>
          </FAQs>

          <Hide breakpoint="(max-width: 1024px)">
            <Socials>
              {socials.map((social) => (
                <SocialLink
                  href={social.ariaLabel}
                  key={social.ariaLabel}
                  aria-label={social.ariaLabel}
                >
                  {social.icon}
                </SocialLink>
              ))}
            </Socials>
          </Hide>
        </StudyHallInfo>

        <CopyRights>
          <span>© 2022 StudyHall. All rights reserved</span>
          <CopyrightSmall>
            <button onClick={() => handleModal('terms')} type="button">
              Terms & Conditions
            </button>
            <button onClick={() => handleModal('privacy')} type="button">
              Privacy Policy
            </button>
          </CopyrightSmall>
        </CopyRights>
      </FooterWrapper>
    </Wrapper>
  );
};

export default Footer;
