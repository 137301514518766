import { getStatusText } from '../../../modules/helpers';
import { UserType } from '../../../types/common';
import Icon from '../Icon/Icon';
import { Status, StatusWrapper } from './StatusText.styles';

type StatusTextProps = {
  status: UserType['status'];
};

const StatusText = ({ status }: StatusTextProps): JSX.Element => (
  <StatusWrapper>
    <Icon type="status" size="sm" color={status || 'relaxing'} />
    <Status status={status || 'relaxing'}>
      {getStatusText(status as string) || 'relaxing'}
    </Status>
  </StatusWrapper>
);

export default StatusText;
