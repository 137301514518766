import { IconButton, Input, InputRightAddon } from '@chakra-ui/react';
import { useState } from 'react';

import { PasswordStateType } from '../../../types/common';
import Icon from '../Icon/Icon';
import { PasswordGroupWrapper } from './InputPassword.styles';

export type InputPasswordProps = {
  id?: string;
  disabled: boolean;
  size?: 'sm' | 'md' | 'lg';
  placeholder?: string;
  register?: object;
  passwordState: PasswordStateType;
};

const InputPassword = ({
  id,
  disabled,
  placeholder,
  size = 'lg',
  register = {},
  passwordState = 'notProvided',
}: InputPasswordProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);

  const passwordHasValidationErrors =
    passwordState === 'required' || passwordState === 'providedAndInvalid';

  return (
    <PasswordGroupWrapper
      size={size}
      variant="password"
      $passwordState={passwordState}
      $isDisabled={disabled}
    >
      <Input
        {...register}
        isDisabled={disabled}
        id={id}
        placeholder={placeholder}
        type={show ? 'text' : 'password'}
        autoComplete="new-password"
      />

      <InputRightAddon bg={disabled ? 'brand.gray25' : 'brand.white'}>
        <>
          {passwordHasValidationErrors && (
            <Icon type="warning" color="red" size="sm" />
          )}

          {passwordState === 'providedAndValid' && (
            <Icon type="checkmark" color="green" size="sm" />
          )}

          <IconButton
            bg="transparent"
            onClick={() => {
              setShow(!show);
            }}
            icon={
              <Icon
                type={show ? 'eyeShow' : 'eyeHide'}
                size="sm"
                color="gray600"
              />
            }
            marginLeft="8px"
            aria-label={show ? 'Show password' : 'Hide password'}
            variant="secondaryIcon"
            size="xxs"
          />
        </>
      </InputRightAddon>
    </PasswordGroupWrapper>
  );
};

export default InputPassword;
