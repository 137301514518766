import { motion } from 'framer-motion';
import styled from 'styled-components';

export const TypingTextAnimation = styled(motion.p)<{
  $isCursorBlinking: boolean;
}>`
  font-weight: 400;
  color: ${(props) => props.theme.color.gray900};
  line-height: 18px;
  font-size: ${(props) => props.theme.font.md};
  white-space: pre-line;

  &::after {
    content: '|';
    font-weight: 900;
    color: #0a47a3;
    ${(props) =>
      props.$isCursorBlinking &&
      `
      animation: blink 1s infinite step-start;
      `}
    opacity: ${(props) => (props.$isCursorBlinking ? 1 : 0)};
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;

export const HighlightedText = styled.span<{ $isBlueColor: boolean }>`
  color: ${(props) =>
    props.$isBlueColor ? props.theme.color.blue800 : props.theme.color.gray900};
  font-weight: 900;
`;
