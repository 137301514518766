import { ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { ImageListType } from 'react-images-uploading';

import ImageCrop from '@/components/learner/ImageCrop/ImageCrop';
import ImageInsert from '@/components/learner/ImageInsert/ImageInsert';

import { ModalContainer, ModalWrapper } from './AddPhotoModal.styles';

type AddPhotoModalProps = {
  isOpen: boolean;
  imageFile: ImageListType;
  onHandleModal: () => void;
  profilePictureUrl: string;
  onChangeImage: (imageFile: ImageListType) => void;
  onResetImage: () => void;
};
const AddPhotoModal = ({
  isOpen,
  imageFile,
  onResetImage,
  onHandleModal,
  profilePictureUrl,
  onChangeImage,
}: AddPhotoModalProps): JSX.Element => {
  const cancelRef = React.useRef<any>();

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onHandleModal}
      variant="addPhoto"
      isCentered
      size="xl"
      autoFocus={false}
      closeOnOverlayClick={false}
      finalFocusRef={cancelRef}
    >
      <ModalOverlay />

      <ModalWrapper>
        {imageFile[0]?.file?.name ? (
          <ImageCrop
            imageFile={imageFile}
            onResetImage={onResetImage}
            onHandleModal={onHandleModal}
          />
        ) : (
          <ImageInsert
            profilePictureUrl={profilePictureUrl}
            imageFile={imageFile}
            onChangeImage={onChangeImage}
            onHandleModal={onHandleModal}
          />
        )}
      </ModalWrapper>
    </ModalContainer>
  );
};

export default AddPhotoModal;
