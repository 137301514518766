import { Tooltip } from '@chakra-ui/react';

type ToolTipTypes = {
  arialLabel: string;
  hoverText: string;
  displayedContent: string | React.ReactNode;
  size?: string;
  iconTop?: number;
  variant?:
    | 'primary'
    | 'secondary'
    | 'centered'
    | 'schoolData'
    | 'topicsCard'
    | 'questionsTip'
    | 'questionsTipBig'
    | 'printTip'
    | 'fullScreenTip'
    | 'modeTip'
    | 'flashcardTip'
    | 'studentAssessmentTip'
    | 'flashcardTipSmall'
    | 'flashcardTipGame1'
    | 'flashcardTipGame2'
    | 'flashcardTipGame3'
    | 'reviewBlackTooltip'
    | 'placticeFlashcardTooltip'
    | 'latestAssignmentsTooltip'
    | 'latestAssignmentsButtonTooltip'
    | 'latestAssignmentsReviewTooltip'
    | 'latestAssignmentsUpDownTooltip'
    | 'printTipBig'
    | 'printSmallTip'
    | 'skillTip'
    | 'smallColored'
    | 'dashboardTooltipShort'
    | 'dashboardTooltipTall'
    | 'dashboardTooltipTallest'
    | 'dashboardTooltipBig'
    | 'smbTooltipBig'
    | 'basic';

  placement?: 'top-end' | 'top' | 'bottom' | 'bottom-end';
  isDisabled?: boolean;
  colorScheme?: string;
  hasArrow?: boolean;
  minWidth?: string;
  textAlign?: 'center' | 'left' | 'right';
  lineHeight?: string;
  isOpen?: boolean;
  openDelay?: number;
  style?: React.CSSProperties | undefined;
};

const ToolTip = ({
  arialLabel,
  hoverText,
  displayedContent,
  size,
  iconTop = 170,
  variant,
  placement = 'top-end',
  isDisabled,
  hasArrow = false,
  style,
  ...props
}: ToolTipTypes): JSX.Element => {
  return (
    <Tooltip
      size={size || 'lg'}
      aria-label={arialLabel}
      zIndex={200}
      label={hoverText}
      placement={placement}
      arrowPadding={hasArrow ? 8 : iconTop}
      arrowSize={15}
      hasArrow={hasArrow}
      isDisabled={isDisabled}
      variant={variant}
      {...props}
    >
      <span style={style}>{displayedContent}</span>
    </Tooltip>
  );
};

export default ToolTip;
