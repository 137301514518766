import { ReactNode } from 'react';

import FieldWarning from '../FieldWarning/FieldWarning';
import { Wrapper } from './GroupWrapper.styles';

type GroupWrapperProps = {
  children: ReactNode;
  isInvalid?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
  hasLeftIcon?: boolean;
  height?: string;
};

const GroupWrapper = ({
  isInvalid = false,
  children,
  variant = 'primary',
  hasLeftIcon = false,
  height = '100%',
}: GroupWrapperProps): JSX.Element => {
  return (
    <Wrapper
      $isInvalid={isInvalid}
      variant={variant}
      $hasLeftIcon={hasLeftIcon}
      height={height}
    >
      {children}
      {isInvalid && <FieldWarning />}
    </Wrapper>
  );
};

export default GroupWrapper;
