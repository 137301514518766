import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import styled from 'styled-components';

type PhonePropsType = {
  $isInvalid: boolean;
};

export const InputPhoneWrapper = styled(InputGroup)<PhonePropsType>`
  border-color: ${(props) =>
    props.$isInvalid
      ? `${props.theme.color.error800} `
      : `${props.theme.color.white}`};

  border-width: 1px;
  border-radius: 8px;
  background-color: ${(props) => props.isDisabled && props.theme.color.gray25};
  color: ${(props) => props.isDisabled && props.theme.color.gray500};
  z-index: 1;

  &:focus-within {
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.color.gray200};

    border-color: ${(props) =>
      props.$isInvalid
        ? `${props.theme.color.error800} `
        : `${props.theme.color.gray200}`};

    & img:nth-child(2) {
      transform: translateX(0px);
    }
  }
`;

export const InputLeftAddonElement = styled(InputLeftAddon)`
  border-color: transparent !important;
`;
export const InputElement = styled(Input)`
  border-color: transparent !important;
`;
