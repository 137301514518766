import { ModalBody } from '@chakra-ui/react';
import styled from 'styled-components';

export const ModalBodyContainer = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => props.theme.space.md};
  position: relative;
`;
