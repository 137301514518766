import { Description, Title, Wrapper } from './SectionHeader.styles';

type SectionHeaderProps = {
  title?: string;
  description?: string;
  mode?: 'normalView' | 'modalView';
};

const SectionHeader = ({
  title,
  description,
  mode,
}: SectionHeaderProps): JSX.Element => {
  return (
    <Wrapper mode={mode}>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </Wrapper>
  );
};

export default SectionHeader;
